import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceInventory {
    lookupService = new APIServiceLookUp();

    async getAllTransaction(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/getAllTransaction`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTransactionByFilter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/getTransactionByFilter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setInvTransaction(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/setInvTransaction`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}