import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { HandPlatter, Building, CalendarClock, Eye, Timer, Edit, Trash } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom, paramsAtom, subAtom } from '../../core/config/atoms';

import { toast } from 'react-toastify';
import Moment from 'moment';
import Tippy from '@tippyjs/react';

import TopBar from '../../core/widgets/ui/TopBar';
import ComponentTimer from 'core/widgets/util/ComponentTimer';
import DigShift from '../../core/widgets/ui/dailogs/DigShift';

import isRoom from '../../assets/image/isRoom.png';
import icTable from '../../assets/image/isTable.png';

import ServiceFloor from 'services/serviceFloor';
import ServiceOrder from 'services/serviceOrder';
import ServiceBooking from 'services/serviceBooking';

export default function PanelPosMain() {

    const [token] = useRecoilState(tokenAtom);
    const [sub] = useRecoilState(subAtom);

    const [, setParam] = useRecoilState(paramsAtom);

    const [floors, setFloors] = useState([]);
    const [filterFloor, setFilterFloor] = useState([]);
    const [orders, setOrders] = useState([]);
    const [filterOrders, setFilterOrders] = useState([]);
    const [bookings, setBookings] = useState([]);

    const [record, setRecord] = useState({});

    const [selFloor, setSelFloor] = useState('all');
    const [tab, setTab] = useState('all');
    const [mainTab, setMainTab] = useState('pos');
    const [bookTab, setBookTab] = useState('taba');

    const [tempURef, setTempURef] = useState('');
    const [tempURT, setTempURT] = useState(-1);

    const [selectionA, setSelectionA] = useState(false);
    const [selectionB, setSelectionB] = useState(false);
    const [selectionC, setSelectionC] = useState(false);

    const navigate = useNavigate();
    const floorService = new ServiceFloor();
    const orderService = new ServiceOrder();
    const bookService = new ServiceBooking();

    const sortTable = (filter) => {
        setSelFloor(filter);

        if (filter === 'all') {
            setFilterFloor(floors);
        } else {
            let tempList = floors.filter((item) => item.title === filter);
            setFilterFloor(tempList);
        }
    }

    const getColor = (sts) => {
        switch (sts) {
            case 'active':
                return 'bg-green-200';
            case 'inprog':
                return 'bg-red-200';
            case 'none':
            default:
                return 'bg-white';
        }
    }

    const gotoOrder = (tableId, tableIndex, type, ordID) => {
        setParam({ 'id': tableId, 'index': tableIndex, 'type': type, 'ordID': ordID });
        navigate(`/home/posOrder`);
    }

    const viewOrder = (tableId, tableIndex) => {
        (async function () {
            var body = {
                'ordRef': tableId,
                'ordRT': tableIndex,
            }
            var res = await orderService.getOrderByUnit(body, token);
            if (res.data.unit.status !== "none") {
                setRecord(res.data);
                setSelectionB(true);
            }
        })();
    }

    const siftUnit = (tableId, tableIndex) => {
        setTempURef(tableId);
        setTempURT(tableIndex);
        setSelectionC(true);
    }

    const gotoBook = (id) => {
        setParam({ 'bid': id });
        navigate(`/home/posBook`);
    }

    const gotoCheckIn = (id) => {
        setParam({ 'bid': id });
        navigate(`/home/posCheckin`);
    }

    const gotoCheckOut = (id) => {
        setParam({ 'bid': id });
        navigate(`/home/posCheckOut`);
    }

    const changeTab = (val) => {
        setTab(val);
        let tempList = [];
        switch (val) {
            case 'takeaway':
                filterOrders.forEach((ord) => {
                    if (ord.ordRef === 'takeaway') {
                        tempList.push(ord);
                    }
                });
                setOrders(tempList);
                break;
            case 'table':
                filterOrders.forEach((ord) => {
                    if (ord.ordRef !== 'takeaway') {
                        tempList.push(ord);
                    }
                });
                setOrders(tempList);
                break;
            case 'all':
            default:
                setOrders(filterOrders);
        }
    }

    const getOrderType = (ord) => {
        if (ord.ordRef === "takeaway") return "Take-Away";
        if (ord.ordRef === "delivery") return "Delivery";
        if (ord.isRoom) return "Room Service";
        if (!ord.isRoom) return "Table Service";
        return "NA"
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'running':
                return " bg-yellow-100 text-yellow-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getChipColorB = (status) => {
        switch (status) {
            case 'active':
                return 'bg-green-100 text-green-700 ';
            case 'overdeu':
                return 'bg-red-100 text-red-700';
            case 'inprog':
                return 'bg-yellow-100 text-yellow-700';
            case 'none':
            default:
                return 'bg-gray-100 text-gray-700';
        }
    }

    const getTotal = (ordItems) => {
        let total = 0;
        ordItems.forEach((a) => {
            total = total + (a.price * a.qty);
        });
        return total;
    }

    const getTax = (ordItems) => {
        let tax = 0;
        ordItems.forEach((a) => {
            tax = tax + ((a.price * a.qty) * 0.025);
        });
        return tax;
    }

    const cancelOrder = (id) => {
        let body = {
            'oid': id,
            "type": "cancel",
            'data': {
                'status': 'cancel'
            }
        };
        orderService.updateOrder(body, token).then((res) => {
            toast.success("Order has been updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            loadData();
        });
    }

    const onClose = () => {
        setTempURef('');
        setTempURT(-1);
        setSelectionB(false);
        setSelectionC(false);
        loadData();
    }

    const selectOrder = (ord) => {
        setRecord(ord);
        setSelectionA(true);
    }

    function getRoundOff(total, tax) {
        const finalTotal = total + (tax * 2);
        const roundedTotal = Math.round(finalTotal);
        const roundOffValue = (roundedTotal - finalTotal).toFixed(2);
        if (roundOffValue === "0.00") {
            return roundOffValue;
        }
        return roundOffValue > 0 ? `+${roundOffValue}` : roundOffValue;
    }

    const cancelBooking = (id) => {
        let body = {
            bid: id,
            data: {
                status: 'canceled'
            }
        }
        bookService.updateBooking(body, token).then((res) => {
            if (res['status']) {
                loadBooking();
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    const getTitleSts = (sts) => {
        switch (sts) {
            case 'open':
                return "Upcoming";
            case 'prog':
                return "On-Going";
            default:
                return "NA";
        }
    }

    const getCssSts = (sts) => {
        switch (sts) {
            case 'open':
                return "text-blue-600";
            case 'prog':
                return "text-yellow-600";
            default:
                return "NA";
        }
    }

    const loadOrder = () => {
        (async function () {
            let ordBody = {
                'filter': {
                    'status': { "$in": ['open', 'running'] }
                }
            };
            var ordRes = await orderService.getAllOrders(ordBody, token);
            setOrders(ordRes.data);
            setFilterOrders(ordRes.data);
        })();
    }

    const loadBooking = () => {
        (async function () {
            var booking = await bookService.getAllBookings({}, token);
            setBookings(booking.data);
        })();
    }

    const loadData = () => {
        (async function () {
            await loadBooking();
            var doc = await floorService.getAllFloor({}, token);
            setFloors(doc.data);
            setFilterFloor(doc.data);
            setSelFloor('all');
            loadOrder();
        })();
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            event.preventDefault();
            if (event.ctrlKey && event.key === 'd') {
                gotoOrder('', 0, 'takeaway');
            } if (event.ctrlKey && event.key === 'a') {

            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {selectionC && <DigShift onClose={onClose} status={selectionC} ordRef={tempURef} ordRT={tempURT} />}
            {
                selectionA && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>KOT #{record.ordID}</h1>
                                    <span onClick={() => {
                                        setSelectionA(false);
                                        setRecord({});
                                        loadOrder();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Order Type:
                                        <span className={`ml-4 px-3 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                            {getOrderType(record)}
                                        </span>
                                    </p>
                                    <p className='ml-8 font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(record.status)}`}>
                                            {record.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-file-invoice mr-2 text-xl"></i>Items Summery
                                    </span>
                                </div>
                                <div className='mt-4 flex items-center'>
                                    <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-bold text-gray-950'>Item Unit</p>
                                </div>
                                <hr className='my-2' />
                                {
                                    record.ordItems.map((item) => {
                                        return <div className='flex items-center'>
                                            <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                            <div className='flex-grow'></div>
                                            <p className='text-sm font-medium'>{item.qty} x ₹ {item.price} </p>
                                        </div>
                                    })
                                }
                                <hr className='my-2' />
                                <div class="w-full mt-2 space-y-1">
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">Subtotal</span>
                                        <span class="font-medium text-sm">₹ {getTotal(record.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">CGST(2.5%)</span>
                                        <span class="font-medium text-sm">₹ {getTax(record.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">SGST(2.5%)</span>
                                        <span class="font-medium text-sm">₹ {getTax(record.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">Round off</span>
                                        <span class="font-medium text-sm">₹ {getRoundOff(getTotal(record.ordItems), getTax(record.ordItems))}</span>
                                    </div>
                                    <div class="border-t-2 mt-2 py-2 flex items-center justify-between">
                                        <span class="font-medium text-lg">Total</span>
                                        <span class="font-medium text-lg">₹ {Math.round((getTotal(record.ordItems) + (getTax(record.ordItems) * 2))).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className='flex mt-4'>
                                    <div class="py-2 mr-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        setSelectionA(false);
                                        gotoOrder(record.ordRef, record.ordRT, record.ordRef === "takeaway" ? "takeaway" : "table", record.ordID);
                                    }}>
                                        Bill Order
                                    </div>
                                    <div class="py-2 ml-2 rounded-md shadow-lg text-center bg-ascent text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        setSelectionA(false);
                                        cancelOrder(record._id);
                                    }}>
                                        CANCEL ORDER
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            {
                selectionB && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>UNIT : {record.unit.title}</h1>
                                    <span onClick={() => {
                                        setSelectionB(false);
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorB(record.unit.status)}`}>
                                            {record.unit.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                {
                                    record.orders.map((order) => {
                                        return <><div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-file-invoice mr-2 text-xl"></i>KOT #{order.ordID}
                                            </span>
                                        </div>
                                            <div className='mt-4 flex items-center'>
                                                <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                                <div className='flex-grow'></div>
                                                <p className='text-sm font-bold text-gray-950'>Item Unit</p>
                                            </div>
                                            <hr className='my-2' />
                                            {
                                                order.ordItems.map((item) => {
                                                    return <div className='flex items-center'>
                                                        <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                                        <div className='flex-grow'></div>
                                                        <p className='text-sm font-medium'>{item.qty} x ₹ {item.price} </p>
                                                    </div>
                                                })
                                            }
                                        </>
                                    })
                                }
                                <hr className='my-2' />
                                <div className='flex mt-4'>
                                    <div class="py-2 mr-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        siftUnit(record.orders[0].ordRef, record.orders[0].ordRT);
                                    }}>
                                        Shift Unit
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            <div className='h-screen w-full'>
                <div className="w-full h-full py-3 px-[4px]">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                                <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                    POS Terminal
                                </h2>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12">
                        <ScrollArea className="w-full h-[100vh] overflow-hidden">
                            <div className='w-full min-h-[100vh] bg-gray-100 rounded-md grid grid-cols-12 gap-2'>
                                <div className='col-span-2 h-full border-r p-1'>
                                    {
                                        sub.isRoom && <div class="w-full h-12 mb-4 rounded-md bg-gray-200">
                                            <div className='h-12'>
                                                <ul className="intro-y flex text-sm font-sans">
                                                    <li className={`px-3 h-8 m-2 rounded flex flex-grow items-center justify-center ${mainTab === 'pos' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { setMainTab('pos') }}>Food</button></li>
                                                    <li className={`px-3 h-8 m-2 rounded flex flex-grow items-center justify-center ${mainTab === 'book' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { setMainTab('book') }}>Bookings</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        mainTab === "pos" && <>
                                            <button className="w-full h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white text-sm" onClick={() => {
                                                gotoOrder('', 0, 'takeaway')
                                            }}><i class="las la-plus mr-1"></i> ADD TAKE AWAY</button>
                                            <ul>
                                                <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === 'all' ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} onClick={() => {
                                                    sortTable('all');
                                                }}>
                                                    All Floors
                                                </li>
                                                {
                                                    floors.length !== 0 && floors.map((cunit) => {
                                                        return <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === cunit.title ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} key={cunit._id}>
                                                            <div className='flex items-center justify-between'>
                                                                <span className='flex-grow' onClick={() => { sortTable(cunit.title); }}>{cunit.title}</span>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }
                                    {
                                        mainTab !== "pos" && <>
                                            <button className="w-full h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white text-sm" onClick={() => { gotoBook(); }}>
                                                <i class="las la-plus mr-1"></i> ADD BOOKING
                                            </button>
                                        </>
                                    }
                                </div>
                                {
                                    mainTab === "book" && <div className='col-span-5 border-r p-2'>
                                        <div className='w-1/2'>
                                            <div class="w-full h-12 rounded-md">
                                                <h2 class="text-xl font-medium font-serif truncate text-ascent">
                                                    Upcoming Bookings
                                                </h2>
                                            </div>
                                        </div>
                                        {
                                            bookings.filter((item) => item.status === "open").length === 0 && <div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                            </div>
                                        }
                                        {
                                            bookings.filter((item) => item.status === "open").map((book) => {
                                                return <div className="col-span-6 border border-gray-300 rounded-lg p-6 shadow-md bg-white flex items-start my-3">
                                                    <div className="flex items-center space-x-4 mr-4">
                                                        <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                            <p className='text-sm font-medium text-gray-600'>FROM</p>
                                                            <p className='text-4xl font-medium'>{Moment(book.sdate).format('DD')}</p>
                                                            <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.edate).format('ddd')}</p>
                                                        </div>
                                                        <div>-</div>
                                                        <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                            <p className='text-sm font-medium text-gray-600'>TO</p>
                                                            <p className='text-4xl font-medium'>{Moment(book.edate).format('DD')}</p>
                                                            <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.edate).format('ddd')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1 flex flex-col items-start">
                                                        <p className=''>Booking ID:&nbsp;<span className='font-medium'>{book.bookid}</span></p>
                                                        <p className='text-sm'>Name:&nbsp;<span className='font-medium'>{book.bookName}</span></p>
                                                        <div className='flex space-x-2'>
                                                            <p className='text-sm text-gray-600 font-medium'>Email:&nbsp;<span>{book.bookEmail}</span></p>
                                                            <p className='text-sm text-gray-600 font-medium'>Phone:&nbsp;<span>{book.bookPhone}</span></p>
                                                        </div>
                                                        <p className='text-sm'>Status:&nbsp;<span className={`font-medium ${getCssSts(book.status)}`}>{(getTitleSts(book.status))}</span></p>
                                                        <div className='flex-grow'></div>
                                                        <div className='flex space-x-2'>
                                                            <Tippy content="CHECK-IN" placement='top'>
                                                                <button className="w-full h-8 py-1 px-3 rounded bg-blue-600 text-white text-sm" onClick={() => { gotoCheckIn(book._id); }}>
                                                                    CHECK-IN
                                                                </button>
                                                            </Tippy>
                                                            <Tippy content="Edit Booking" placement='top'>
                                                                <button className="w-10 h-8 px-3 rounded bg-blue-600 text-white text-sm" onClick={() => { gotoBook(book._id); }}>
                                                                    <Edit size={16} />
                                                                </button>
                                                            </Tippy>
                                                            <Tippy content="Cancel Booking" placement='top'>
                                                                <button className="w-10 h-8 px-3 rounded bg-red-600 text-white text-sm" onClick={() => { cancelBooking(book._id); }}>
                                                                    <Trash size={16} />
                                                                </button>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                                {
                                    mainTab === "book" && <div className='col-span-5 border-r p-2'>
                                        <div className='w-1/2'>
                                            <div class="w-full h-12 rounded-md">
                                                <h2 class="text-xl font-medium font-serif truncate text-ascent">
                                                    Ongoing Bookings
                                                </h2>
                                            </div>
                                        </div>
                                        {
                                            bookings.filter((item) => item.status == "prog").length === 0 && <div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                            </div>
                                        }
                                        {
                                            bookings.filter((item) => item.status === "prog").map((book) => {
                                                return <div className='col-span-6 border border-gray-300 rounded-lg p-6 shadow-md bg-white'>
                                                    <div className=" flex items-start my-3">
                                                        <div className="flex items-center space-x-4 mr-4">
                                                            <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                                <p className='text-sm font-medium text-gray-600'>FROM</p>
                                                                <p className='text-4xl font-medium'>{Moment(book.sdate).format('DD')}</p>
                                                                <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.sdate).format('ddd')}</p>
                                                            </div>
                                                            <div>-</div>
                                                            <div className='min-w-[100px] flex flex-col items-center border bg-gray-50 border-prime p-2 rounded-md space-y-4'>
                                                                <p className='text-sm font-medium text-gray-600'>TO</p>
                                                                <p className='text-4xl font-medium'>{Moment(book.edate).format('DD')}</p>
                                                                <p className='text-sm font-medium text-gray-600 uppercase'>{Moment(book.edate).format('ddd')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="space-y-1 flex flex-col items-start">
                                                            <p className=''>Booking ID:&nbsp;<span className='font-medium'>{book.bookid}</span></p>
                                                            <p className='text-sm'>Name:&nbsp;<span className='font-medium'>{book.bookName}</span></p>
                                                            <div className='flex space-x-2'>
                                                                <p className='text-sm text-gray-600 font-medium'>Email:&nbsp;<span>{book.bookEmail}</span></p>
                                                                <p className='text-sm text-gray-600 font-medium'>Phone:&nbsp;<span>{book.bookPhone}</span></p>
                                                            </div>
                                                            <p className='text-sm'>Status:&nbsp;<span className={`font-medium ${getCssSts(book.status)}`}>{(getTitleSts(book.status))}</span></p>
                                                            <div className='flex-grow'></div>
                                                            <div className='flex space-x-2'>
                                                                <button className="w-full h-8 py-1 px-3 rounded bg-red-600 text-white text-sm" onClick={() => { gotoCheckOut(book._id); }}>
                                                                    CHECK-OUT
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-1'>
                                                        {
                                                            book.rooms.map((room, rindex) => {
                                                                return <div className="p-2 rounded col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md bg-white" key={rindex}>
                                                                    <div className='flex justify-between items-center px-1'>
                                                                        <div>
                                                                            <p className='text-sm font-medium font-serif'>{room.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                                {
                                    mainTab === "pos" && <div className='col-span-7'>
                                        {
                                            filterFloor.length !== 0 && filterFloor.map((cuint) => {
                                                return <div className='w-full my-3 border border-gray-200 border-dashed p-1 rounded'>
                                                    <div className='w-full'>
                                                        <div className='flex items-center justify-between'>
                                                            <div className='flex items-center'>
                                                                <Building size={22} />
                                                                <h1 className='text-xl font-medium uppercase ml-1'>{cuint.title}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='my-2 h-[1px] bg-gray-300'></div>
                                                    </div>
                                                    <div className='w-full grid grid-cols-4 gap-2'>
                                                        {
                                                            cuint.units.length === 0 && < div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                                <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                                            </div>
                                                        }
                                                        {
                                                            cuint.units.length !== 0 && cuint.units.map((unit, index) => {
                                                                if (unit.type === 'Room') {
                                                                    return < div className={`h-[114px] p-2 rounded shadow col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${getColor(unit.status)}`} key={index} onClick={() => {
                                                                        if (unit.status !== 'none') {
                                                                            gotoOrder(cuint._id, index, 'table');
                                                                        }
                                                                    }}>
                                                                        <div className='flex justify-between items-center px-1'>
                                                                            <div>
                                                                                <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                <div className='flex items-center'>
                                                                                    <CalendarClock size={18} />
                                                                                    <ComponentTimer startTime={Moment(unit.endTime).add(12, 'hours').toISOString()} isOn={unit.status !== 'none'} isElapsed={true} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='bg-gray-200 h-[1px] my-2'></div>
                                                                        <div className='flex items-center justify-between'>
                                                                            <div className='text-gray-600 flex items-center'>
                                                                                <HandPlatter size={20} />
                                                                                <p className='text-base font-medium font-serif ml-1'>₹ {unit.pending.toFixed(2)}</p>
                                                                            </div>
                                                                            <Eye size={18} className='cursor-pointer border border-red-700 p-1 rounded' onClick={() => {
                                                                                if (unit.pending > 0) {
                                                                                    viewOrder(cuint._id, index);
                                                                                }
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                } else {
                                                                    return < div className={`h-[114px] p-2 rounded shadow col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${getColor(unit.status)}`} key={index}>
                                                                        <div className='flex justify-between items-center px-1' onClick={() => { gotoOrder(cuint._id, index, 'table'); }}>
                                                                            <div>
                                                                                <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                <p className='text-base font-medium font-serif'>₹ {unit.pending.toFixed(2)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='bg-gray-200 h-[1px] my-2'></div>
                                                                        <div className='flex items-center justify-between'>
                                                                            <div className='text-gray-600 flex items-center'>
                                                                                <Timer size={20} />
                                                                                <ComponentTimer startTime={unit.startTime} isOn={unit.status !== 'none'} isElapsed={false} />
                                                                            </div>
                                                                            <Eye size={18} className='cursor-pointer border border-red-700 p-1 rounded' onClick={() => { viewOrder(cuint._id, index); }} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                                {
                                    mainTab === "pos" && <div className='col-span-3'>
                                        <div className='flex items-center justify-between mt-2'>
                                            <h2 class="text-xl font-medium font-serif truncate text-ascent">
                                                Running Orders
                                            </h2>
                                        </div>
                                        <div class="w-full flex justify-between items-center">
                                            <div className='h-12 bg-gray-100 rounded'>
                                                <ul className="intro-y flex text-sm font-sans space-x-2 my-2">
                                                    <li className={`px-3 h-8 rounded flex items-center justify-center ${tab === 'all' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                                    <li className={`px-3 h-8 rounded flex items-center justify-center ${tab === 'takeaway' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('takeaway') }}>Take-Away</button></li>
                                                    <li className={`px-3 h-8 rounded flex items-center justify-center ${tab === 'table' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('table') }}>Table</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr />
                                        <ScrollArea className="w-full h-[90vh] flex flex-col">
                                            {
                                                orders.length === 0 && <div className='w-full text-prime items-center justify-center mt-8'>
                                                    <p className='text-sm font-medium text-center'>No orders found.</p>
                                                </div>
                                            }
                                            {
                                                orders.map((ord) => {
                                                    return <div className={`w-full h-[120px] bg-white my-2 px-2 py-2 border rounded-md justify-between cursor-pointer hover:shadow-md border-gray-200`} key={ord._id} onClick={() => {
                                                        selectOrder(ord);
                                                    }}>
                                                        <div className="font-medium text-gray-900 text-xl"><b>{ord.ordID}</b></div>
                                                        <div className="font-medium text-sm text-gray-600">{ord.ordItems.length} x Items</div>
                                                        <div className="font-medium mt-2">Order Total : ₹ <span className='font-bold'>{(ord.ordTotal + (ord.ordTax * 2)).toFixed(2)}</span></div>
                                                        <hr />
                                                        <div className="font-medium text-red-600 text-xs my-1">{getOrderType(ord)}</div>
                                                    </div>
                                                })
                                            }
                                        </ScrollArea>
                                    </div>
                                }
                            </div>
                            <div className='h-10 w-full'></div>
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </>
    )
}
