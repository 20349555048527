import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";

import { Calendar as CalendarIcon } from "lucide-react";

import TopBar from 'core/widgets/ui/TopBar';

import ServiceBooking from 'services/serviceBooking';
import { PrintRoomBill } from 'core/widgets/ui/PrintRoomBill';

export default function PanelBooking(props) {

    const contentRef = useRef(null);

    const [token] = useRecoilState(tokenAtom);

    const [bookings, setBookings] = useState([]);

    const [filter, setFilter] = useState({});
    const [ord, setOrd] = useState({});

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isPrintComp, setIsPrintComp] = useState(false);

    const navigate = useNavigate();
    const bookService = new ServiceBooking();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setDate({
            from: Moment().startOf('year').toDate(),
            to: Moment().toDate(),
        });
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        // users.forEach((item) => {
        //     items.push({
        //         uid: item.uid,
        //         fname: item.fname,
        //         mname: item.mname,
        //         lname: item.lname,
        //         email: item.email,
        //         phone: item.phone,
        //         role: item.role,
        //         status: item.status,
        //         createdAt: item.createdAt,
        //     });
        // });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "staff");
        XLSX.writeFile(workbook, "staff.xlsx");
    }

    const exportToCsv = () => {
        // let headers = ['uid,fname,mname,lname,email,phone,role,status'];
        // let usersCsv = users.reduce((acc, user) => {
        //     const { uid, fname, mname, lname, email, phone, role, status } = user
        //     acc.push([uid, fname, mname, lname, email, phone, role, status].join(','))
        //     return acc
        // }, []);
        // downloadFile({
        //     data: [...headers, ...usersCsv].join('\n'),
        //     fileName: 'staff.csv',
        //     fileType: 'text/csv',
        // });
    }

    const updateStatus = (id, status) => {
        // let body = {
        //     uid: id,
        //     data: {
        //         status: status
        //     }
        // }
        // staffService.updateStaffById(body, token).then((res) => {
        //     if (res['status']) {
        //         loadData();
        //     } else {
        //         toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        //     }
        // });
    }

    const deleteStaff = (id) => {
        // let body = {
        //     uid: id,
        // }
        // staffService.deleteStaffById(body, token).then((res) => {
        //     if (res['status']) {
        //         loadData();
        //     } else {
        //         toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        //     }
        // });
    }

    const openPanel = (order) => {
        setOrd(order);
        setIsOpen(true);
    }

    const getChipColorA = (status) => {
        switch (status) {
            // case 'open':
            //     return " bg-blue-100 text-blue-800";
            case 'prog':
                return " bg-yellow-100 text-yellow-800";
            // case 'prog':
            //     return " bg-green-100 text-green-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const handleAfterPrint = React.useCallback(() => {
        setIsPrintComp(false);
        console.log("2")
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        return Promise.resolve();
    }, []);

    const printOption = () => {
        setIsPrintComp(true);
    }

    const printFn = useReactToPrint({
        contentRef: contentRef,
        documentTitle: "Room Invoice",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await bookService.getAllBookingReport(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setBookings(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await bookService.getAllBookingReport(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setBookings(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    useEffect(() => {
        if (isPrintComp) {
            printFn();
        }
    }, [isPrintComp]);

    return (
        <>
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>Booking ID #<span className='text-blue-600'>{ord.bookid}</span></h1>
                                    <span onClick={() => {
                                        setIsOpen(false);
                                        setOrd({});
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Order Type:
                                        <span className={`ml-4 px-3 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                            Room Booking
                                        </span>
                                    </p>
                                    <p className='ml-8 font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(ord.status)}`}>
                                            {ord.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-file-invoice mr-2 text-xl"></i>Booking Summery
                                    </span>
                                </div>
                                <div className='my-2 flex flex-col'>
                                    <p className='text-base font-medium'>{ord.bookName}</p>
                                    <p className='text-xs text-gray-700'>Email: {ord.bookEmail}</p>
                                    <p className='text-xs text-gray-700'>Phone: {ord.bookPhone}</p>
                                    <hr className='my-2' />
                                    <p className='text-sm text-gray-700'>{ord.bookAddr}</p>
                                </div>
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-file-invoice mr-2 text-xl"></i>Accommodation Summery
                                    </span>
                                </div>
                                <div className='my-2'>
                                    <div className="text-base font-bold">Accommodation Charges</div>
                                    <div className="text-left">
                                        {
                                            ord.inventory.map((item) => {
                                                return <div className="font-medium">
                                                    <div className="text-xs">{item.title} * {item.qty} Room</div>
                                                    <div className="text-xs text-gray-600">{item.nop} Adult / {item.plus} Child</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="text-sm font-medium mt-2">Check IN Time: {Moment(ord.sdate).add(11, 'hours').format('DD MMM YYYY hh:mm A')}</div>
                                    <div className="text-sm font-medium mb-2">Check OUT Time: {Moment(ord.edate).add(12, 'hours').format('DD MMM YYYY hh:mm A')}</div>
                                </div>
                                <hr className='my-2' />
                                <div class="w-full mt-2 space-y-1">
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">Amount</span>
                                        <span class="font-medium text-sm">₹ {(ord.total - ord.discount).toFixed(2)} /-</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-medium text-sm">@Discount (-)</span>
                                        <span class="font-medium text-sm">₹ {ord.discount.toFixed(2)} /-</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-medium text-sm">CGST (6%)</span>
                                        <span class="font-medium text-sm">₹ {(ord.tax / 2).toFixed(2)} /-</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-medium text-sm">SGST (6%)</span>
                                        <span class="font-medium text-sm">₹ {(ord.tax / 2).toFixed(2)} /-</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-medium text-sm">Net Amount</span>
                                        <span class="font-medium text-sm">₹ {ord.finaltotal.toFixed(2)} /-</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-medium text-sm">Paid</span>
                                        <span class="font-medium text-sm">₹ {ord.paid.toFixed(2)} /-</span>
                                    </div>
                                    <div class="border-t-2 mt-3 py-2 flex items-center justify-between">
                                        <span class="font-medium text-base">Pending Total</span>
                                        <span class="font-medium text-base">₹ {(ord.finaltotal - ord.paid).toFixed(2)} /-</span>
                                    </div>
                                </div>
                                <div className='flex mt-4'>
                                    <div class="py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => { printOption(); }}>
                                        PRINT BOOKING
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            <ScrollArea className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Booking Tally
                            </h2>
                            <TopBar />
                        </div>
                        <div class="w-full mt-4 flex justify-between items-center">
                            <div className='h-12 bg-gray-100 rounded'>
                                <ul className="intro-y flex text-sm font-sans">
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'all' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'active' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('active') }}>Active</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'paid' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('paid') }}>Paid</button></li>
                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'cancel' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('cancel') }}>Cancelled</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={7}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-3 flex'>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button id="date" variant={"outline"}
                                                                className={cn(
                                                                    "w-[300px] justify-start text-left font-normal",
                                                                    !date && "text-muted-foreground"
                                                                )}
                                                            >
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {date?.from ? (
                                                                    date.to ? (
                                                                        <>
                                                                            {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                            {Moment(date.to).format('MMM DD, yyyy')}
                                                                        </>
                                                                    ) : (
                                                                        Moment(date.from).format('MMM DD, yyyy')
                                                                    )
                                                                ) : (
                                                                    <span>Pick a date</span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                initialFocus
                                                                mode="range"
                                                                defaultMonth={date?.from}
                                                                selected={date}
                                                                onSelect={onDateChange}
                                                                numberOfMonths={2}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className='w-full flex col-span-9 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and email." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToExcel();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToCsv();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Booking ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Customer Details
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Room Details
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Check-In
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Check-Out
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    bookings.length !== 0 && bookings.map((book) => {
                                        return <tr className="hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-prime uppercase' onClick={() => { openPanel(book) }}>
                                                        {book.bookid}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {book.bookName}<br />
                                                    <span className='text-xs text-gray-700'>{book.bookEmail} {book.bookPhone}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 w-[240px]">
                                                <div className="text-xs font-bold flex">
                                                    {
                                                        book.rooms.map((room, index) => {
                                                            return <div className='bg-green-200 text-green-800 px-2 py-[1px] rounded-full' key={index}>{room.title}</div>
                                                        })
                                                    }
                                                    {
                                                        book.rooms.length === 0 && <div className='text-sm'>-</div>
                                                    }
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {Moment(book.sdate).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {Moment(book.edate).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${book.status == 'closed' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                    {book.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {Moment(book.createdAt).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    bookings.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
            <PrintRoomBill ref={contentRef} isVis={isPrintComp} book={ord} />
        </>
    )
}
