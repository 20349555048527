import * as React from "react";

import { useRecoilState } from 'recoil';
import { companyAtom } from 'core/config/atoms';

import moment from "moment";

export const PrintKOT = React.forwardRef((props, ref) => {

    const [comp] = useRecoilState(companyAtom);

    return (
        <div className={`mx-auto my-5 w-[220px] text-sm p-2 ${props.isVis ? '' : 'hidden'}`} ref={ref}>
            <div className="space-y-1 border border-gray-800 bg-white">
                <h1 className="text-lg font-bold text-center">KOT RECEIPT</h1>
                <div className="text-center mb-3 flex flex-col items-center my-2 space-y-1">
                    <h2 className="text-center font-medium">{comp.tradNme}</h2>
                    <p className="text-center text-sm max-w-[240px]">{comp.Addr}</p>
                    <p className="text-center text-sm">Ph: {comp.phone}</p>
                </div>
                <div className="bg-black h-[1px]"></div>
                <div className="bg-black h-[1px]"></div>
                <div className="mt-2 text-xs px-2 space-y-1">
                    <p className=""><strong>KOT No:</strong> {props.kot.ordID}</p>
                    <div className="flex items-center justify-between">
                        <p className=""><strong>Date:</strong> {moment(props.kot.ordTime).format("DD, MMM YY HH:MM A")}</p>
                        <p className=""><strong>Table:</strong> {props.kot.unit}</p>
                    </div>
                </div>
                <div className="bg-black h-[1px]"></div>
                <table className="w-full border-collapse text-sm mt-2">
                    <thead>
                        <tr>
                            <th className="border border-black py-[2px] text-center">Sr No</th>
                            <th className="border border-black py-[2px] text-center">Qty</th>
                            <th className="border border-black py-[2px] text-center">Item Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.kot.ordItems !== undefined && props.kot.ordItems.map((item, index) => {
                                return <tr>
                                    <td className="border border-black py-[2px] text-center">{index + 1}</td>
                                    <td className="border border-black py-[2px] text-center">{item.qty}</td>
                                    <td className="border border-black py-[2px] text-center">{item.name}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <div className="text-center mt-3 font-bold text-sm">
                    <p>No Order Instructions</p>
                </div>
            </div>
        </div>
    )
});
