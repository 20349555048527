import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, userRoleAtom, tokenAtom, subAtom } from '../core/config/atoms';

import { AnimatePresence, motion } from "framer-motion";
import { toast } from 'react-toastify';

import { User, CreditCard, Printer, Building2, HandPlatter, BedSingle, CookingPot, Store } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelSettings from 'panels/settings/PanelSettings';
import PanelSettingCompany from 'panels/settings/PanelSettingCompany';
import PanelBilling from 'panels/settings/PanelBilling';
import PanelPrinters from 'panels/settings/PanelPrinters';
import PanelSettingsTable from 'panels/settings/PanelSettingsTable';
import PanelSettingsRoom from 'panels/settings/PanelSettingsRoom';
import PanelSettingsRoomType from 'panels/settings/PanelSettingsRoomType';
import PanelSettingsMenu from 'panels/settings/PanelSettingsMenu';
import PanelSettingsMenuAdd from 'panels/settings/PanelSettingsMenuAdd';
import PanelSettingsMenuEdit from 'panels/settings/PanelSettingsMenuEdit';

import PanelFranchiseList from 'panels/settings/franchise/PanelFranchiseList';
import PanelFranchiseAdd from 'panels/settings/franchise/PanelFranchiseAdd';
import PanelFranchiseEdit from 'panels/settings/franchise/PanelFranchiseEdit';

import ServiceSubscription from '../services/serviceSubscription';

export default function LayoutSetting() {

    const [userData, setUserData] = useRecoilState(userAtom);
    const [userRole] = useRecoilState(userRoleAtom);
    const [token] = useRecoilState(tokenAtom);
    const [sub] = useRecoilState(subAtom);

    const { page } = useParams();

    const [Subscription, setSubscription] = useState({});

    const [isOpen, setIsOpen] = useState(true);

    const navigate = useNavigate();
    const subscriptionService = new ServiceSubscription();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 10,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await subscriptionService.getAllSubscription(body, token);
            if (res['status']) {
                setSubscription(res.data.data[0]);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '13rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium p-1 m-2 rounded">Settings</motion.h1>
                        <motion.div initial="closed" animate="open" exit="closed" variants={itemVariants}>
                            <p className='text-sm uppercase p-1 mx-2'>{userData.fname} {userData.lname}</p>
                        </motion.div>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-52 flex flex-col items-start">
                            <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'setting' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('setting') }}>
                                <User size={20} className='mr-2' /> User Profile
                            </li>
                            {
                                userRole === 'ADMIN' && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'subscription' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('subscription') }}>
                                    <CreditCard size={20} className='mr-2' /> Billing
                                </li>
                            }
                            {
                                (userRole === 'ADMIN' || userRole === 'MANAGER') && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'print' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('print') }}>
                                    <Printer size={20} className='mr-2' /> Print Settings
                                </li>
                            }
                            {
                                (userRole === 'ADMIN' || userRole === 'MANAGER') && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'company' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('company') }}>
                                    <Building2 size={20} className='mr-2' /> Company Settings
                                </li>
                            }
                        </motion.ul>
                        {
                            (userRole === 'ADMIN' || userRole === 'MANAGER') && <motion.div initial="closed" animate="open" exit="closed" variants={itemVariants}>
                                <p className='text-sm uppercase p-1 mx-2 mt-2'>{Subscription.name}</p>
                            </motion.div>
                        }
                        {
                            (userRole === 'ADMIN' || userRole === 'MANAGER') && <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                                {
                                    sub.isFranchise && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'franchise' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('franchise') }}>
                                        <Store size={20} className='mr-2' /> Franchises
                                    </li>
                                }
                                <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'table' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('table') }}>
                                    <HandPlatter size={20} className='mr-2' /> Table Setup
                                </li>
                                {
                                    sub.isRoom && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'room' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('room') }}>
                                        <BedSingle size={20} className='mr-2' /> Room Setup
                                    </li>
                                }
                                {
                                    sub.isRoom && <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'roomType' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('roomType') }}>
                                        <BedSingle size={20} className='mr-2' /> Room Type Setup
                                    </li>
                                }
                                <li className={`w-[192px] cursor-pointer text-base p-1 mx-2 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'menu' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('menu') }}>
                                    <CookingPot size={20} className='mr-2' /> Menu Setup
                                </li>
                            </motion.ul>
                        }
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'setting':
                                return (<PanelSettings onToggle={toggleMenu} />);
                            case 'subscription':
                                return (<PanelBilling onToggle={toggleMenu} />);
                            case 'print':
                                return (<PanelPrinters onToggle={toggleMenu} onClose={closeMenu} />);
                            case 'company':
                                return (<PanelSettingCompany onToggle={toggleMenu} />);
                            case 'franchise':
                                return (<PanelFranchiseList onToggle={toggleMenu} />);
                            case 'franchiseAdd':
                                return (<PanelFranchiseAdd onToggle={toggleMenu} />);
                            case 'franchiseEdit':
                                return (<PanelFranchiseEdit onToggle={toggleMenu} />);
                            case 'table':
                                return (<PanelSettingsTable onToggle={toggleMenu} />);
                            case 'room':
                                return (<PanelSettingsRoom onToggle={toggleMenu} />);
                            case 'roomType':
                                return (<PanelSettingsRoomType onToggle={toggleMenu} />);
                            case 'menu':
                                return (<PanelSettingsMenu onToggle={toggleMenu} closeMenu={closeMenu} />);
                            case 'menuAdd':
                                return (<PanelSettingsMenuAdd onToggle={toggleMenu} />);
                            case 'menuEdit':
                                return (<PanelSettingsMenuEdit onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
