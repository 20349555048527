import * as React from "react";

import { useRecoilState } from 'recoil';
import { companyAtom } from 'core/config/atoms';

import moment from "moment";
import { ToWords } from 'to-words';

export const PrintRoomBill = React.forwardRef((props, ref) => {

    const toWords = new ToWords();

    const [comp] = useRecoilState(companyAtom);

    return (
        <div className={`mx-auto my-5 h-[1000px] w-[800px] border border-gray-800 bg-white text-sm ${props.isVis ? '' : 'hidden'}`} ref={ref}>
            {
                Object.keys(props.book).length !== 0 && <>
                    <div className="flex border-b border-gray-300">
                        <div className="w-full text-center">
                            <h2 className="font-serif font-bold text-2xl mb-2">{comp.tradNme}</h2>
                            <p className="mb-2">
                                {comp.Addr}
                            </p>
                            <p className="mb-2">Tel: <b>{comp.phone}</b> &nbsp;&nbsp; Email: <b>{comp.email}</b></p>
                            <p className="mb-2">GSTIN: <b>{comp.gstn}</b></p>
                        </div>
                    </div>

                    <div className="w-full border-t border-gray-300"></div>
                    <div className="w-full border-b border-gray-300">
                        <h4 className="font-bold uppercase text-center text-xl">TAX INVOICE</h4>
                    </div>
                    <div className="w-full border-b border-gray-300"></div>

                    <div className="flex border-b border-gray-300">
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">
                            Invoice Date:
                        </div>
                        <div className="w-1/6 p-2 border-r border-gray-300">{moment().format('DD MMM YYYY')}</div>
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">
                            Reverse Charge:
                        </div>
                        <div className="w-1/6 p-2 border-r border-gray-300">NO</div>
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">
                            Invoice Number:
                        </div>
                        <div className="w-1/6 p-2">{props.book.bookInv}</div>
                    </div>

                    <div className="flex border-b border-gray-300">
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">To:</div>
                        <div className="w-1/3 p-2 border-r border-gray-300">{props.book.bookName}</div>
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">GST No:</div>
                        <div className="w-1/3 p-2">{props.book.bookGSTN}</div>
                    </div>

                    <div className="flex border-b border-gray-300">
                        <div className="w-1/6 p-2 border-r border-gray-300 font-bold">Address:</div>
                        <div className="w-5/6 p-2">{props.book.bookAddr}</div>
                    </div>

                    <div className="border-b border-gray-300 w-full grid grid-cols-1 gap-0">
                        <div className="col-span-1 grid grid-cols-12 gap-0">
                            <div className="col-span-1 font-bold text-center">Sr.</div>
                            <div className="col-span-3 font-bold text-center">Particulars</div>
                            <div className="col-span-3 font-bold text-center">Check in Time</div>
                            <div className="col-span-3 font-bold text-center">Check out Time</div>
                            <div className="col-span-2 font-bold text-center">Cost</div>
                        </div>
                    </div>

                    <div className="border-b border-gray-300 w-full grid grid-cols-1 gap-0" style={{ minHeight: "280px" }}>
                        <div className="col-span-1 grid grid-cols-12 gap-0">
                            <div className="col-span-1 text-center">1</div>
                            <div className="col-span-3 text-center flex flex-col items-start">
                                <div className="text-base font-bold">Accommodation Charges</div>
                                <div className="text-left">
                                    {
                                        props.book.inventory.map((item) => {
                                            return <div className="font-medium">
                                                <div className="text-xs">{item.title} * {item.qty} Room</div>
                                                <div className="text-xs text-gray-600">{item.nop} Adult / {item.plus} Child</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-span-3 text-center">{moment(props.book.sdate).add(11, 'hours').format('DD MMM YYYY hh:mm A')}</div>
                            <div className="col-span-3 text-center">{moment(props.book.edate).add(12, 'hours').format('DD MMM YYYY hh:mm A')}</div>
                            <div className="col-span-2 text-center">₹ {props.book.total.toFixed(2)} /-</div>
                        </div>
                        <div className="col-span-1 grid grid-cols-12 gap-0">
                            <div className="col-span-1 text-center"></div>
                            <div className="col-span-3 text-center flex flex-col items-start">
                                <div className="text-base font-bold">@ Discount</div>
                            </div>
                            <div className="col-span-3 text-center">-</div>
                            <div className="col-span-3 text-center">-</div>
                            <div className="col-span-2 text-center">- ₹ {props.book.discount.toFixed(2)} /-</div>
                        </div>
                    </div>

                    <div className="flex border-b border-gray-300">
                        <div className="w-1/2 text-center font-bold">
                            <p className="text-lg">Total Invoice Amount in Words</p>
                            <div className="text-xs">{toWords.convert(props.book.finaltotal)}</div>
                        </div>
                        <div className="w-1/2 border-l border-gray-300">
                            <div className="flex border-b border-gray-300">
                                <div className="w-1/3 text-right font-bold">Amount</div>
                                <div className="w-1/3">&nbsp;</div>
                                <div className="w-1/3 text-right mr-3">₹ {(props.book.total - props.book.discount).toFixed(2)} /-</div>
                            </div>
                            <div className="flex border-b border-gray-300">
                                <div className="w-1/3 text-right font-bold">SGST</div>
                                <div className="w-1/3 text-right">(6) %</div>
                                <div className="w-1/3 text-right mr-3">₹ {(props.book.tax / 2).toFixed(2)} /-</div>
                            </div>
                            <div className="flex border-b border-gray-300">
                                <div className="w-1/3 text-right font-bold">CGST</div>
                                <div className="w-1/3 text-right">(6) %</div>
                                <div className="w-1/3 text-right mr-3">₹ {(props.book.tax / 2).toFixed(2)} /-</div>
                            </div>
                            <div className="flex border-b border-gray-300">
                                <div className="w-1/3 text-right font-bold">Net Amount</div>
                                <div className="w-1/3">&nbsp;</div>
                                <div className="w-1/3 text-right mr-3">₹ {props.book.finaltotal.toFixed(2)} /-</div>
                            </div>
                            <div className="flex border-b border-gray-300">
                                <div className="w-1/3 text-right font-bold">Paid</div>
                                <div className="w-1/3 text-right"></div>
                                <div className="w-1/3 text-right mr-3">₹ {props.book.paid.toFixed(2)} /-</div>
                            </div>
                        </div>
                    </div>

                    <div class="row text-center font-bold italic mt-1 text-lg">
                        <p>CERTIFIED THAT PARTICULAR GIVEN ARE TRUE AND CORRECT</p>
                        <div class="mt-4 ml-auto text-sm italic md:w-1/4 md:mr-12">
                            {comp.tradNme}
                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="mt-4 ml-auto text-sm italic md:w-1/4 md:mr-12">
                            Authorised Signatory
                        </div>
                        <br />
                        <p class="mt-4 text-sm">This is a computer-generated invoice</p>
                    </div>
                </>
            }
        </div>
    )
});
