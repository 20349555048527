import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom, paramsAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { motion } from "framer-motion";

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceMenu from '../../services/serviceMenu';
import ServiceRawwItem from '../../services/serviceRawwItem';
import ServiceBOM from '../../services/serviceBom';

export default function PanelBomAdd(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);
    const [param, setParam] = useRecoilState(paramsAtom);

    const [editId, setEditId] = useState('');
    const [rItem, setRItem] = useState('');
    const [qtyr, setQTYR] = useState('');

    const [menuItems, setMenuItem] = useState([]);
    const [rawwItems, setRawwItem] = useState([]);
    const [rawwList, setRawwList] = useState([]);
    const [recipeItem, setRecipeItem] = useState([]);

    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const navigate = useNavigate();
    const menuService = new ServiceMenu();
    const rawwItemService = new ServiceRawwItem();
    const bomService = new ServiceBOM();

    const formVSchema = Yup.object().shape({
        menuItem: Yup.string().required('This information is required'),
        note: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            menuItem: '',
            note: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (recipeItem.length === 0) {
                toast.error('Please select at least one raww material to create BOM.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                setSubmit(true);
                if (isEdit) {
                    // let body = {
                    //     "uid": editId,
                    //     "data": {
                    //         "itemName": values.name,
                    //         "itemCategory": values.cat,
                    //         "itemUnit": values.unit,
                    //         "itemMini": values.mini,
                    //     }
                    // }
                    // rawwItemService.updateRawwItem(body, token).then((res) => {
                    //     setSubmit(false);
                    //     if (res.status) {
                    //         toast.success("Raww Item Updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    //         goBack();
                    //     } else {
                    //         toast.error('Server error please try again..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    //     }
                    // });
                } else {
                    let body = {
                        "id": generateID(),
                        "item": values.menuItem,
                        "name": menuItems.find((a) => a.value == values.menuItem).label,
                        "note": values.note,
                        "items": recipeItem,
                        "uid": userData._id,
                    }
                    bomService.setBOM(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success("New BOM added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            goBack();
                        } else {
                            toast.error('Server error please try again..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            }
        }
    });

    const goBack = () => {
        props.onOpen();
        navigate(`/home/bom`);
    }

    const generateID = () => {
        const timestamp = Date.now().toString();
        const randomNum = Math.floor(Math.random() * 10000).toString();
        const uniqueID = timestamp.slice(-6) + randomNum;
        return "BOM" + uniqueID.slice(0, 10);
    };

    const handleChangeForm = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case "qtyr":
                setQTYR(value);
                break;
            case "rItem":
                setRItem(value);
                break;
        }
    }

    const addItems = () => {
        if (rItem !== "" && qtyr !== "") {
            let tempList = [...recipeItem];
            const raww = rawwList.find((a) => a._id == rItem);
            tempList.push({
                "id": rItem,
                "name": raww.itemName,
                "unit": raww.itemUnit,
                "qty": qtyr,
            });
            setRecipeItem(tempList);
            setRItem('');
            setQTYR('');
        } else {
            toast.error('Please fill all the information.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const removeItems = (index) => {
        let tempList = [...recipeItem];
        tempList.splice(index, 1);
        setRecipeItem(tempList);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 300,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await menuService.getAllMenu(body, token);
            if (res['status']) {
                let TempVals = [];
                res.data.data.map((item) => {
                    TempVals.push({ value: item._id, label: item.name });
                });
                setMenuItem(TempVals);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }

            body = {
                'status': 'active',
                'fid': userData.fid
            };
            var resA = await rawwItemService.getAllRawwItems(body, token);
            if (resA['status']) {
                let TempVals = [];
                resA.data.map((item) => {
                    TempVals.push({ value: item._id, label: `${item.itemName} - ${item.itemUnit}` });
                });
                setRawwList(resA.data);
                setRawwItem(TempVals);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Add Bill of Material
                        </h2>
                        <TopBar />
                    </div>
                </div>
            </div>
            <div className="w-full h-full p-2 flex flex-col overflow-auto">
                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                            <i className="las la-long-arrow-alt-left text-xl"></i>
                            <h4 className="text-xs text-black font-sans font-medium ml-2">Back to BOM List</h4>
                        </div>
                        <div className="intro-y mt-4">
                            <h3 className="text-2xl font-sans font-bold text-gray-800">Add BOM</h3>
                            <p className="mt-1 text-sm text-gray-600 mb-6">
                                Please enter corret details and information related to BOM.
                            </p>
                            <hr />
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">BOM Bill Information</h3>
                                                </div>
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Menu Item<sup className="text-red-600">*</sup></label>
                                                    <select id="menuItem" className="appearance-none mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" onChange={handleChange} value={values.menuItem}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            menuItems.length !== 0 && menuItems.map((item) => {
                                                                return <option value={item.value} selected={values.menuItem == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.menuItem && touched.menuItem) && <p className='text-xs text-red-400 mt-1'>{errors.menuItem}</p>}
                                                </div>
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">BOM Note.<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="note" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Bill of material note" value={values.note} />
                                                    {(errors.note && touched.note) && <p className='text-xs text-red-400 mt-1'>{errors.note}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">BOM Recipe Information</h3>
                                                </div>

                                                <div className="col-span-12 grid grid-cols-6 gap-3">
                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Select Item:<sup className="text-red-600">*</sup></label>
                                                        <select id="rItem" name="rItem" className="appearance-none mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" onChange={handleChangeForm} value={rItem}>
                                                            <option value="" disabled="" selected="selected">Select</option>
                                                            {
                                                                rawwItems.length !== 0 && rawwItems.map((item) => {
                                                                    return <option value={item.value} selected={rItem == item.value}>{item.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Item Quantity Required:<sup className="text-red-600">*</sup></label>
                                                        <input type="number" name="qtyr" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 100" step="any" value={qtyr} />
                                                    </div>

                                                    <button type="button" disabled={submit} className="inline-flex h-10 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-4" onClick={() => {
                                                        addItems();
                                                    }}>ADD</button>
                                                </div>

                                                <div className="col-span-12">
                                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM NAME
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM UNIT
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    ITEM Quantity
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    -
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {
                                                                recipeItem.length !== 0 && recipeItem.map((item, index) => {
                                                                    return <tr className="hover:bg-gray-100" key={item._id}>
                                                                        <td className="px-6 py-2 whitespace-nowrap cursor-pointer">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                <div className='flex items-center text-prime uppercase'>
                                                                                    {item.name}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                                {item.unit}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                                {item.qty}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-2 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                <button type="button" disabled={submit} className="inline-flex h-8 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-4" onClick={() => {
                                                                                    removeItems(index);
                                                                                }}>REMOVE</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                recipeItem.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="4">
                                                                        <div className="text-sm text-red-600 text-center">
                                                                            No data found.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                            <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && (isEdit ? "UPDATE BOM" : "ADD BOM")}
                            </button>
                        </div>
                    </form >
                </div >
            </div>
        </ScrollArea>
    )
}
