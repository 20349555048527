import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import { useRecoilState } from 'recoil';
import { subAtom, tokenAtom, companyAtom } from '../core/config/atoms';

import moment from 'moment';
import { toast } from 'react-toastify';

import LayoutPos from '../layouts/LayoutPos';
import LayoutKDS from '../layouts/LayoutKDS';
import LayoutOrder from '../layouts/LayoutOrder';
import LayoutInventory from '../layouts/LayoutInventory';
import LayoutStaff from '../layouts/LayoutStaff';
import LayoutReports from '../layouts/LayoutReports';
import LayoutSetting from '../layouts/LayoutSetting';

import { useReactToPrint } from "react-to-print";

import ServicePrinter from 'services/servicePrinter';

import socket from 'core/config/socket';

import { PrintKOT } from 'core/widgets/ui/PrintKOT';
import { PrintBill } from 'core/widgets/ui/PrintBill';

export default function PageHome() {

    const contentARef = useRef(null);
    const contentBRef = useRef(null);

    const [token] = useRecoilState(tokenAtom);
    const [sub] = useRecoilState(subAtom);
    const [comp] = useRecoilState(companyAtom);

    const [kot, setKot] = useState({});
    const [bill, setBill] = useState({});

    const [isPrintKOT, setIsPrintKOT] = useState(false);
    const [isPrintBill, setIsPrintBill] = useState(false);

    const { page } = useParams();

    const printService = new ServicePrinter();

    const getBarCss = () => {
        const givenDate = moment(sub.endDate);
        const currentDate = moment().startOf('day');
        const dayDifference = currentDate.diff(givenDate, 'days') * -1;
        if (dayDifference <= 14) {
            return 'bg-red-600 text-white';
        } else if (dayDifference <= 30) {
            return 'bg-yellow-100 text-yellow-600';
        }
        return 'hidden';
    }

    const getContent = () => {
        const givenDate = moment(sub.endDate);
        const currentDate = moment().startOf('day');
        const dayDifference = currentDate.diff(givenDate, 'days') * -1;
        if (dayDifference <= 14) {
            return <h1>Your subscription is ending in {dayDifference} days. <span className='underline'>Please Renew</span></h1>;
        } else if (dayDifference <= 30) {
            return <h1>Your subscription is ending soon. <span className='underline'>Please Renew</span></h1>;
        }
        return <></>;
    }

    const sendToPrint = (payload, type) => {
        if (window.electronAPI === undefined) {
            if (type.includes("K")) {
                setKot(payload);
                setIsPrintKOT(true);
            } else {
                setBill(payload);
                setIsPrintBill(true);
            }
        } else {
            printService.getAllPrinterByType({ 'type': type, 'stauts': 'active' }, token).then((res) => {
                if (res.status) {
                    res.data.map((item) => {
                        if (type.includes("K")) {
                            payload.comp = comp;
                            let kotRecipt = formatKOTReceipt(payload);
                            window.electronAPI.printKOT(kotRecipt, item.name, `${item.size}mm`).then((resPrint) => {
                                console.log(resPrint);
                                // if (!resPrint.success) {
                                //     toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                // }
                            });
                        } else {
                            payload.comp = comp;
                            let billRecipt = formatBillReceipt(payload);
                            window.electronAPI.printBill(billRecipt, item.name, `${item.size}mm`).then((resPrint) => {
                                console.log(resPrint);
                                // if (!resPrint.success) {
                                //     toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                // }
                            });
                        }
                    });
                }
            });
        }
    }

    function getRoundOff(total, tax) {
        const finalTotal = total + (tax * 2);
        const roundedTotal = Math.round(finalTotal);
        const roundOffValue = (roundedTotal - finalTotal).toFixed(2);
        if (roundOffValue === "0.00") {
            return roundOffValue;
        }
        return roundOffValue > 0 ? `+ ${roundOffValue}` : roundOffValue;
    }

    const formatBillReceipt = (bill) => {
        const receipt = [];
        let itemTotal = bill.total;
        let itemDiscount = 0;
        if (bill.dmode === "A") {
            itemDiscount = bill.discount;
        } else {
            itemDiscount = bill.total * (bill.discount / 100);
        }
        let itemTax = bill.tax;

        receipt.push({
            type: 'text',
            value: '<h1>Tax Invoice</h1>',
            style: { width: '100%', fontWeight: "bold", fontSize: "16px", marginBottom: "8px", textAlign: 'center' }
        });

        let rows = bill.items.map((item, index) => `<tr>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${index + 1}</td>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${item.name}</td>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${item.qty}</td>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${item.price}</td>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${(item.qty * item.price).toFixed(2)}</td>
            </tr>`).join('');

        receipt.push({
            type: 'text',
            value: `<div style="margin: 10px auto; max-width: 420px; background: white;">
                        <div style="padding: 10px; border: 1px solid #000;">
                            <div style="text-align: center; margin-bottom: 10px;">
                                <h2 style="margin: 6px 0; font-size: 18px;">${bill.comp.tradNme}</h2>                        
                                <p style="margin: 1px auto; font-size: 12px; max-width: 200px;">${bill.comp.Addr}</p>
                                <p style="margin: 1px auto; font-size: 12px;">Ph: ${bill.comp.phone}</p>
                                <p style="margin: 1px auto; font-size: 12px;">GSTN: ${bill.comp.gstn}</p>
                            </div>

                            <div style="margin-top: 10px; font-size: 12px; display: flex; justify-content: space-between;">
                                <div style="display: flex;">
                                    <p><strong>Customer:</strong></p>                
                                    <div style="width: 60px; height: 1px; background: #000; margin-top: 28px;"></div>                
                                </div>
                                <p><strong>Invoice No:</strong> ${bill.invnNum}</p>
                            </div>
                            <div style="font-size: 12px; display: flex; justify-content: space-between;">            
                                <p style="margin: 3px 0;"><strong>Date:</strong> ${new Date(bill.createdAt).toLocaleString()}</p>
                                <p style="margin: 3px 0;"><strong>Table:</strong> ${bill.unit}</p>
                            </div>
    
                            <table style="width: 100%; border-collapse: collapse; font-size: 12px; margin-top: 10px;">
                                <thead>
                                    <tr>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Sr No</th>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Particulars</th>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Qty</th>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Price</th>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Total</th>
                                    </tr>                
                                </thead>
                                <tbody>
                                    ${rows}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>Sub Total</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${itemTotal.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>@ Discount</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${itemDiscount.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>CGST (2.5%)</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${itemTax.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>SGST (2.5%)</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${itemTax.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>Round off</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${getRoundOff(itemTotal, itemTax)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>Service Charges</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right;"><strong>₹ ${bill.serCharge.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="border: 1px solid #000; padding: 5px; text-align: right; font-size: 14px;"><strong>Grand Total</strong></td>
                                        <td colspan="2"style="border: 1px solid #000; padding: 5px; text-align: right; font-size: 14px;"><strong>₹ ${(Math.round((itemTotal - itemDiscount) + (itemTax * 2)) + bill.serCharge).toFixed(2)}</strong></td>
                                    </tr>
                                </tfoot>
                            </table>
    
                            <div style="text-align: center; margin-top: 10px; font-weight: bold;">
                                <p>Thank You, Visit Again !!!</p>
                            </div>
                        </div>
                    </div>`,
            style: { width: '100%' }
        });

        return receipt;
    }

    function formatKOTReceipt(kot) {
        const receipt = [];
        let itemTotal = 0;
        let total = 0;
        kot.ordItems.map(item => {
            itemTotal = itemTotal + item.qty;
            total = total + (item.qty * parseFloat(item.price));
        });

        receipt.push({
            type: 'text',
            value: '<h1>KOT RECEIPT</h1>',
            style: { width: '100%', fontWeight: "bold", fontSize: "16px", marginBottom: "8px", textAlign: 'center' }
        });

        let rows = kot.ordItems.map((item) => `<tr>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${item.qty}</td>
                <td style="border: 1px solid #000; padding: 5px; text-align: center;">${item.name}</td>                        
            </tr>`).join('');

        receipt.push({
            type: 'text',
            value: `<div style="margin: 10px auto; max-width: 420px; background: white;">
                        <div style="padding: 10px; border: 1px solid #000;">
                            <div style="text-align: center; margin-bottom: 10px;">
                                <h2 style="margin: 6px 0; font-size: 18px;">${kot.comp.tradNme}</h2>                        
                                <p style="margin: 1px auto; font-size: 12px; max-width: 200px;">${kot.comp.Addr}</p>
                                <p style="margin: 1px auto; font-size: 12px;">Ph: ${kot.comp.phone}</p>
                            </div>
    
                            <div style="margin-top: 10px; font-size: 12px;">            
                                <p style="margin: 3px 0;"><strong>KOT No:</strong> ${kot.ordID}</p>
                                <p style="margin: 3px 0;"><strong>Date:</strong> ${new Date(kot.ordTime).toLocaleString()}</p>
                                <p style="margin: 3px 0;"><strong>Table:</strong> ${kot.unit}</p>
                            </div>
    
                            <table style="width: 100%; border-collapse: collapse; font-size: 12px; margin-top: 10px;">
                                <thead>
                                    <tr>                                        
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Qty</th>
                                        <th style="border: 1px solid #000; padding: 5px; text-align: center;">Item Name</th>
                                    </tr>                
                                </thead>
                                <tbody>
                                    ${rows}
                                </tbody>            
                            </table>
                    
                            <div style="text-align: center; margin-top: 10px; font-weight: bold;">
                                <p>No Order Instructions</p>
                            </div>
                        </div>
                    </div>`,
            style: { width: '100%' }
        });

        return receipt;
    }

    const handlePrintKOT = (payload) => {
        console.log(payload);
        sendToPrint(payload, 'K');
    };

    const handlePrintBill = (payload) => {
        console.log(payload);
        sendToPrint(payload, 'B');
    };

    const handleAfterPrint = React.useCallback(() => {
        setIsPrintKOT(false);
        setIsPrintBill(false);
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        return Promise.resolve();
    }, []);

    const printFnKot = useReactToPrint({
        contentRef: contentARef,
        documentTitle: "KOT",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });

    const printFnBill = useReactToPrint({
        contentRef: contentBRef,
        documentTitle: "Bill",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });

    useEffect(() => {
        socket.on('printKOT', handlePrintKOT);
        socket.on('printBill', handlePrintBill);

        return () => {
            socket.off('printKOT', handlePrintKOT);
            socket.off('printBill', handlePrintBill);
        };
    }, []);

    useEffect(() => {
        if (isPrintKOT) {
            printFnKot();
        }
    }, [isPrintKOT]);

    useEffect(() => {
        if (isPrintBill) {
            printFnBill();
        }
    }, [isPrintBill]);

    return (
        <>
            <PrintKOT ref={contentARef} isVis={isPrintKOT} kot={kot} />
            <PrintBill ref={contentBRef} isVis={isPrintBill} bill={bill} />
            <div className='w-full h-screen overflow-hidden flex relative'>
                <div className={`h-8 w-[50%] absolute rounded-b-md text-center font-medium top-0 left-[25%] flex items-center justify-center ${getBarCss()}`}>
                    {getContent()}
                </div>
                <NavigationBar />
                {(() => {
                    switch (page) {
                        case 'pos':
                        case 'posOrder':
                        case 'posBook':
                        case 'posCheckin':
                        case 'posCheckOut':
                            return (<LayoutPos />);
                        case 'kds':
                            return (<LayoutKDS />);
                        case 'order':
                        case 'invoices':
                        case 'booking':
                            return (<LayoutOrder />);
                        case 'inventory':
                        case 'inventoryList':
                        case 'inventoryAdd':
                        case 'raww':
                        case 'rawwAdd':
                        case 'bom':
                        case 'bomAdd':
                            return (<LayoutInventory />);
                        case 'staff':
                        case 'staffAdd':
                        case 'staffEdit':
                            return (<LayoutStaff />);
                        case 'reports':
                        case 'reportItem':
                        case 'reportOrder':
                        case 'reportInvoice':
                        case 'reportBooking':
                            return (<LayoutReports />);
                        case 'setting':
                        case 'subscription':
                        case 'print':
                        case 'company':
                        case 'franchise':
                        case 'franchiseAdd':
                        case 'franchiseEdit':
                        case 'table':
                        case 'room':
                        case 'roomType':
                        case 'menu':
                        case 'menuAdd':
                        case 'menuEdit':
                            return (<LayoutSetting />);
                        default:
                            return (
                                <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                    No Data Found
                                </div>
                            );
                    }
                })()}
            </div>
        </>
    )
}
