import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { Calendar as CalendarIcon } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom, paramsAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Moment from 'moment';
import { useReactToPrint } from "react-to-print";

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceFloor from 'services/serviceFloor';
import ServiceBooking from 'services/serviceBooking';
import { PrintRoomBill } from 'core/widgets/ui/PrintRoomBill';

export default function PanelPosBook() {

    const contentRef = useRef(null);

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramsAtom);

    const [types, setTypes] = useState([]);
    const [book, setBook] = useState({});

    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDate, setIsDate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isPrintComp, setIsPrintComp] = useState(false);

    const [date, setDate] = useState({
        from: Moment().toDate(),
        to: Moment().add(1, 'day').toDate(),
    });

    const navigate = useNavigate();
    const floorService = new ServiceFloor();
    const bookService = new ServiceBooking();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        nop: Yup.string().required('This information is required'),
        nor: Yup.string().required('This information is required'),
        total: Yup.string().required('This information is required'),
        tax: Yup.string().required('This information is required'),
        discount: Yup.string().required('This information is required'),
        advance: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            gstn: '',
            addr: '',
            nop: 0,
            nor: 0,
            total: 0,
            tax: 0,
            discount: 0,
            advance: 0,
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (isDate) {
                if (types.filter((item) => item.qty !== 0).length === 0) {
                    toast.error('Please select room type.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    if (isEdit) {
                        const taxA = (values.total - values.discount) * 0.12;
                        var body = {
                            "bid": param.bid,
                            "data": {
                                "bookName": values.name,
                                "bookEmail": values.email,
                                "bookPhone": values.phone,
                                "bookAddr": values.addr,
                                "bookGSTN": values.gstn === '' ? "-" : values.gstn,
                                "bookInv": getinovID(),
                                "sdate": date.from,
                                "edate": date.to,
                                "nop": values.nop,
                                "nor": values.nor,
                                "inventory": types.filter((item) => item.qty !== 0),
                                "total": values.total,
                                "discount": values.discount,
                                "tax": taxA,
                                "finaltotal": (values.total - values.discount) + taxA,
                                "paid": values.advance,
                            }
                        };
                        bookService.updateBooking(body, token).then((res) => {
                            if (res.status) {
                                toast.success('Room booking updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            } else {
                                toast.error('Server error please try again later.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        const taxA = (values.total - values.discount) * 0.12;
                        var body = {
                            "bookid": getBID(),
                            "bookName": values.name,
                            "bookEmail": values.email,
                            "bookPhone": values.phone,
                            "bookAddr": values.addr,
                            "bookGSTN": values.gstn === '' ? "-" : values.gstn,
                            "bookInv": getinovID(),
                            "sdate": date.from,
                            "edate": date.to,
                            "nop": values.nop,
                            "nor": values.nor,
                            "inventory": types.filter((item) => item.qty !== 0),
                            "total": values.total,
                            "discount": values.discount,
                            "tax": taxA,
                            "finaltotal": (values.total - values.discount) + taxA,
                            "paid": values.advance,
                        };
                        bookService.setBooking(body, token).then((res) => {
                            if (res.status) {
                                toast.success('Room booking added.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            } else {
                                toast.error('Server error please try again later.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    }
                }
            } else {
                toast.error('Please select booking dates.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        }
    });

    const getinovID = () => {
        return "INV" + Moment().format("MMDDYY");
    }

    const goBack = () => {
        setParam({});
        navigate(`/home/pos`);
    }

    const getBID = () => {
        const timestamp = new Date().getTime();
        const randomString = generateRandomString(6);
        return `BID-${timestamp}-${randomString}`;
    }

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const addQty = (index) => {
        let tempList = [...types];
        tempList[index].qty = tempList[index].qty + 1;
        setTypes(tempList);
        calTotal();
    }

    const removeQty = (index) => {
        let tempList = [...types];
        if (tempList[index].qty > 0) {
            tempList[index].qty = tempList[index].qty - 1;
            setTypes(tempList);
            calTotal();
        }
    }

    const getBooking = () => {
        (async function () {
            if (param.bid !== undefined) {
                var docBook = await bookService.getBookById({ 'id': param.bid }, token);
                setIsEdit(true);
                const tempBook = docBook.data;
                setValues({
                    name: tempBook.bookName,
                    email: tempBook.bookEmail,
                    phone: tempBook.bookPhone,
                    addr: tempBook.bookAddr,
                    nop: tempBook.nop,
                    nor: tempBook.nor,
                    total: tempBook.total,
                    tax: tempBook.tax,
                    discount: tempBook.discount,
                    advance: tempBook.paid,
                });
                var tempList = [...types];
                tempBook.inventory.map((item) => {
                    const index = types.findIndex((a) => a._id === item._id);
                    if (index !== -1) {
                        tempList[index].qty = item.qty;
                    }
                });
                setDate({
                    from: Moment(tempBook.sdate).toDate(),
                    to: Moment(tempBook.edate).toDate(),
                });
                setIsDate(true);
            }
        })();
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            var docType = await floorService.getAllRoomType({}, token);
            let tempType = [];
            docType.data.map((item) => {
                tempType.push({ ...item, qty: 0 });
            });
            setTypes(tempType);
            setLoading(false);
        })();
    }

    const getDayRate = (dayIndex, rates) => {
        const rateKeys = ['ratea', 'rateb', 'ratec', 'rated', 'ratee', 'ratef', 'rateg'];
        return rates[rateKeys[dayIndex]];
    };

    const calTotal = () => {
        let totalAmount = 0;
        const fromDate = Moment(date.from);
        const toDate = Moment(date.to);

        for (let m = fromDate.clone(); m.isBefore(toDate); m.add(1, 'days')) {
            const dayIndex = m.day() - 1;
            const validDayIndex = (dayIndex < 0 ? 6 : dayIndex);
            types.forEach((room) => {
                const rate = getDayRate(validDayIndex, room);
                totalAmount += rate * room.qty;
            });
        }
        setValues({ ...values, total: totalAmount });
    }

    const handlePrint = () => {
        if (isDate) {
            if (types.filter((item) => item.qty !== 0).length === 0) {
                toast.error('Please select room type.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                if (isEdit) {
                    const taxA = (values.total - values.discount) * 0.12;
                    var body = {
                        "bid": param.bid,
                        "data": {
                            "bookName": values.name,
                            "bookEmail": values.email,
                            "bookPhone": values.phone,
                            "bookAddr": values.addr,
                            "bookGSTN": values.gstn === '' ? "-" : values.gstn,
                            "bookInv": getinovID(),
                            "sdate": date.from,
                            "edate": date.to,
                            "nop": values.nop,
                            "nor": values.nor,
                            "inventory": types.filter((item) => item.qty !== 0),
                            "total": values.total,
                            "discount": values.discount,
                            "tax": taxA,
                            "finaltotal": (values.total - values.discount) + taxA,
                            "paid": values.advance,
                        }
                    };
                    bookService.updateBooking(body, token).then((res) => {
                        if (res.status) {
                            setBook(res.data);
                            toast.success('Room booking updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setIsPrintComp(true);
                        } else {
                            toast.error('Server error please try again later.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                } else {
                    const taxA = (values.total - values.discount) * 0.12;
                    var body = {
                        "bookid": getBID(),
                        "bookName": values.name,
                        "bookEmail": values.email,
                        "bookPhone": values.phone,
                        "bookAddr": values.addr,
                        "bookGSTN": values.gstn === '' ? "-" : values.gstn,
                        "bookInv": getinovID(),
                        "sdate": date.from,
                        "edate": date.to,
                        "nop": values.nop,
                        "nor": values.nor,
                        "inventory": types.filter((item) => item.qty !== 0),
                        "total": values.total,
                        "discount": values.discount,
                        "tax": taxA,
                        "finaltotal": (values.total - values.discount) + taxA,
                        "paid": values.advance,
                    };
                    bookService.setBooking(body, token).then((res) => {
                        if (res.status) {
                            setBook(res.data);
                            toast.success('Room booking added.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setIsPrintComp(true);
                        } else {
                            toast.error('Server error please try again later.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            }
        } else {
            toast.error('Please select booking dates.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const handleAfterPrint = React.useCallback(() => {
        setIsPrintComp(false);
        goBack();
        console.log("2")
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        return Promise.resolve();
    }, []);

    const printFn = useReactToPrint({
        contentRef: contentRef,
        documentTitle: "Bill",
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (types.length !== 0) {
            if (Object.keys(param).length !== 0) {
                getBooking();
            }
        }
    }, [types, param]);

    useEffect(() => {
        if (isPrintComp) {
            printFn();
        }
    }, [isPrintComp]);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all bookings</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">{isEdit ? 'UPDATE' : 'Add'} Booking</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to booking.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Booking person email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Booking person name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Phone number" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">GSTN Number&nbsp;<span className="text-gray-600">(optional)</span></label>
                                                <input type="text" id="gstn" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Customer GSTN" value={values.gstn} />
                                                {(errors.gstn && touched.gstn) && <p className='text-xs text-red-400 mt-1'>{errors.gstn}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address</label>
                                                <textarea id="addr" onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Street address" value={values.addr}></textarea>
                                                {(errors.addr && touched.addr) && <p className='text-xs text-red-400 mt-1'>{errors.addr}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Details</h3>
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Booking dates<sup className="text-red-600">*</sup></label>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button id="date" variant={"outline"} className={cn("mt-1 w-full justify-start text-left font-normal", !date && "text-muted-foreground")}>
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {date?.from ? (
                                                                date.to ? (
                                                                    <>
                                                                        {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                        {Moment(date.to).format('MMM DD, yyyy')}
                                                                    </>
                                                                ) : (
                                                                    Moment(date.from).format('MMM DD, yyyy')
                                                                )
                                                            ) : (
                                                                <span>Pick a date</span>
                                                            )}
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0" align="start">
                                                        <Calendar
                                                            initialFocus
                                                            mode="range"
                                                            defaultMonth={date?.from}
                                                            selected={date}
                                                            onSelect={onDateChange}
                                                            numberOfMonths={2}
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Number of pepole<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="nop" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1" value={values.nop} />
                                                {(errors.nop && touched.nop) && <p className='text-xs text-red-400 mt-1'>{errors.nop}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Number of room<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="nor" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1" value={values.nor} />
                                                {(errors.nor && touched.nor) && <p className='text-xs text-red-400 mt-1'>{errors.nor}</p>}
                                            </div>

                                            <div className="col-span-6"></div>

                                            <div className="col-span-12 grid grid-cols-12 gap-8">
                                                {
                                                    types.length !== 0 && types.map((type, index) => {
                                                        return <div className='col-span-3 bg-white border p-4 rounded flex items-center justify-between'>
                                                            <div>
                                                                <h1 className='text-xl font-medium font-poppins'>{type.title}</h1>
                                                                <p className='text-sm'>Number of pepole {type.nop} + {type.plus}</p>
                                                            </div>
                                                            <div className='flex items-start space-x-4'>
                                                                <div className='bg-prime w-8 h-8 rounded text-white flex items-center justify-center cursor-pointer' onClick={() => { removeQty(index) }}>-</div>
                                                                <div>{type.qty}</div>
                                                                <div className='bg-prime w-8 h-8 rounded text-white flex items-center justify-center cursor-pointer' onClick={() => { addQty(index) }}>+</div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Costing</h3>
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total as per system<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="total" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.total} />
                                                {(errors.total && touched.total) && <p className='text-xs text-red-400 mt-1'>{errors.total}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total Discount<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="discount" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.discount} />
                                                {(errors.discount && touched.discount) && <p className='text-xs text-red-400 mt-1'>{errors.discount}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total CGST(6%)<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="tax" onChange={handleChange} autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={(values.total - values.discount) * 0.06} />
                                                {(errors.tax && touched.tax) && <p className='text-xs text-red-400 mt-1'>{errors.tax}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Total SGST(6%)<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="tax" onChange={handleChange} autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={(values.total - values.discount) * 0.06} />
                                                {(errors.tax && touched.tax) && <p className='text-xs text-red-400 mt-1'>{errors.tax}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Net Total<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="finaltotal" autoComplete="off" readOnly={true} className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={(values.total - values.discount) + ((values.total - values.discount) * 0.12)} />
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Advance Paid<sup className="text-red-600">*</sup></label>
                                                <input type="number" id="advance" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 1000" value={values.advance} />
                                                {(errors.advance && touched.advance) && <p className='text-xs text-red-400 mt-1'>{errors.advance}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE BOOKING" : "ADD BOOKING")}
                        </button>
                        <button type="button" disabled={submit} className="inline-flex justify-center ml-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { handlePrint() }}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE BOOKING & PRINT" : "ADD BOOKING & PRINT")}
                        </button>
                    </div>
                </form >
                <PrintRoomBill ref={contentRef} isVis={isPrintComp} book={book} />
            </div >
        </div >
    )
}
