import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";
import { Switch } from "@/components/ui/switch";

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from 'core/config/atoms';

import { CookingPot, HandPlatter } from 'lucide-react';
import { toast } from 'react-toastify';
import Moment from 'moment';

import ServiceOrder from 'services/serviceOrder';
import ServiceFloor from 'services/serviceFloor';

export default function LayoutKDS() {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [orders, setOrders] = useState([]);
    const [items, setItems] = useState([]);
    const [floors, setFloors] = useState([]);

    const orderService = new ServiceOrder();
    const floorService = new ServiceFloor();

    const loadData = () => {
        (async function () {
            var doc = await floorService.getAllFloor({}, token);
            setFloors(doc.data);

            let ordBody = {
                'filter': {
                    'status': 'open'
                }
            };
            var ordRes = await orderService.getAllOrders(ordBody, token);
            setOrders(ordRes.data);
            const itemList = [];
            ordRes.data.forEach(ord => {
                ord.ordItems.forEach((item) => {
                    itemList.push(item);
                });
            });
            setItems(itemList);
        })();
    }

    const updateOrder = (ord, index) => {
        let itemList = [...ord.ordItems];
        if (itemList[index].status === "none") {
            itemList[index].status = "kot";
        } else {
            itemList[index].status = "none";
        }
        let body = {
            "oid": ord._id,
            "type": "bom",
            "data": {
                "ordItems": itemList
            }
        }
        orderService.updateOrder(body, token).then((res) => {
            loadData();
        });
    }

    const getOverView = () => {
        const organizedData = [];

        items.forEach(item => {
            if (item.status === 'none') {
                const category = item.category;
                if (!organizedData[category]) {
                    organizedData[category] = [];
                }
                organizedData[category].push(item);
            }
        });

        const result = Object.values(organizedData);
        return result;
    }

    const getSubItem = (ordItems) => {
        const mergedData = [];
        ordItems.forEach(item => {
            const itemName = item.name;
            if (!mergedData[itemName]) {
                mergedData[itemName] = { ...item };
            } else {
                mergedData[itemName].qty += item.qty;
            }
        });
        const result = Object.values(mergedData);
        return result;
    }

    const getStsColor = (ord) => {
        let isDone = true;

        for (let item of ord.ordItems) {
            if (item.status === "none") {
                isDone = false;
                break;
            }
        }

        return isDone ? "bg-green-600" : "bg-orange-600";
    }

    const dispatchOrder = (ord) => {
        if (getStsColor(ord) === "bg-green-600") {
            let itemList = [];
            ord.ordItems.forEach((it) => {
                it.status = "done";
                itemList.push(it);
            });
            let body = {
                "oid": ord._id,
                "type": "dispatch",
                "ref": userData._id,
                "data": {
                    "ordItems": itemList,
                    "status": "running"
                }
            }
            orderService.updateOrder(body, token).then((res) => {
                loadData();
            });
        } else {
            toast.warn('Please complete all the order items.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const forceDispatchOrder = (ord) => {
        let itemList = [];
        ord.ordItems.forEach((it) => {
            it.status = "done";
            itemList.push(it);
        });
        let body = {
            "oid": ord._id,
            "type": "dispatch",
            "ref": userData._id,
            "data": {
                "ordItems": itemList,
                "status": "running"
            }
        }
        orderService.updateOrder(body, token).then((res) => {
            loadData();
        });
    }

    const getUnitTitle = (ref, index) => {
        if (ref !== "takeaway") {
            var itemUnit = floors.filter((item) => item._id === ref)[0];
            return itemUnit.units[index].title;
        } else {
            return "Take-Away";
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className='h-screen w-full'>
            <div className='flex'>
                <div className='w-[20%] h-screen border-r'>
                    <h1 className="text-xl font-medium px-2 py-1 my-1 rounded">KDS Overview</h1>
                    {
                        getOverView().length === 0 && <div className='flex items-center justify-center min-h-[400px] border border-red-400 border-dashed rounded-md m-1'>
                            <div className='text-red-600'>No Data Found.</div>
                        </div>
                    }
                    {
                        getOverView().length !== 0 && getOverView().map((item, index) => {
                            return <div className='m-2 p-2 bg-white border shadow rounded' key={index}>
                                <h1 className='text-lg font-medium'>{item[0].category}</h1>
                                <hr />
                                <ul className='mt-2'>
                                    {
                                        getSubItem(item).map((sub) => {
                                            return <li className='flex justify-between items-center my-2'>
                                                <div className='text-sm'>{sub.name}</div>
                                                <div className='text-sm font-medium px-4 py-1 rounded-full border'>{sub.qty}</div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        })
                    }
                </div>
                <div className='w-[80%] h-screen'>
                    <h1 className="text-xl font-medium px-2 py-1 my-1 rounded">KOT Orders</h1>
                    {
                        orders.length === 0 && <div className='flex items-center justify-center min-h-[400px] border border-red-400 border-dashed rounded-md m-1'>
                            <div className='text-red-600'>No new kot orders found.</div>
                        </div>
                    }
                    {
                        orders.length !== 0 && <div className='w-full grid grid-cols-4'>
                            {
                                orders.map((ord, index) => {
                                    return <div className='m-2 bg-white border shadow rounded-md col-span-1' key={index}>
                                        <div className={`flex items-center justify-between p-[8px] rounded-t-md ${getStsColor(ord)}`}>
                                            <div>
                                                <div className='text-xs text-white'>Order: #{ord.ordID}</div>
                                                <div className='text-xs text-white'>{Moment(ord.updatedAt).format('hh:mm a')}</div>
                                            </div>
                                            <CookingPot size={28} className='text-white' />
                                        </div>
                                        <div className='flex items-center justify-between p-2'>
                                            <div className='text-sm text-gray-900 font-medium'>{getUnitTitle(ord.ordRef, ord.ordRT)}</div>
                                            <div className='flex'>
                                                <HandPlatter size={20} className='text-gray-900 mr-1' />
                                                <div className='text-sm text-gray-900'>{ord.provider}</div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div>
                                            <ul className='p-2'>
                                                {
                                                    ord.ordItems.map((ordi, index) => {
                                                        return <li className='flex items-center justify-between my-1'>
                                                            <div className='text-sm'>{ordi.qty} X {ordi.name}</div>
                                                            <Switch checked={ordi.status !== "none"} onCheckedChange={() => { updateOrder(ord, index); }} />
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className='m-1 border border-dashed border-orange-600 p-1 rounded cursor-pointer' onClick={(e) => {
                                            e.stopPropagation();
                                            dispatchOrder(ord);
                                        }} onDoubleClick={() => {
                                            forceDispatchOrder(ord);
                                        }}>
                                            <div className="text-base font-medium text-center text-orange-600">Dispatch</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </ScrollArea>
    )
}
