import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceRawwItem {
    lookupService = new APIServiceLookUp();

    async getAllRawwItem(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/getAllRawwItem`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateRawwItem(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/updateRawwItem`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delRawwItem(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/delRawwItem`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getRawwItemById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/getRawwItemById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setRawwItem(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/setRawwItem`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllRawwItems(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}rawwM/getAllRawwItems`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllStock(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/getAllStock`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}