import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";

import ServiceOverview from 'services/serviceOverview';

export default function ComponentListChart(props) {

    const [token] = useRecoilState(tokenAtom);

    const [chartData, setChartData] = useState([
        { pmode: "Cash", total: 0 },
        { pmode: "UPI", total: 0 },
        { pmode: "Card", total: 0 },
        { pmode: "Bank", total: 0 },
        { pmode: "NC", total: 0 },
    ]);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const chartConfig = {
        total: {
            label: "Total",
            color: "#2563eb",
        },
    }

    const loadData = () => {
        (async function () {
            if (props.range !== undefined && props.range.from !== undefined && props.range.to !== undefined) {
                let body = {
                    "startDate": props.range.from.toISOString(),
                    "endDate": props.range.to.toISOString(),
                }
                var res = await overviewService.getCharData(body, token);
                setChartData(res.data);
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [props.range]);

    return (
        <div className='col-span-4'>
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Sales Chart | Payment Mode</h3>
                </div>
                <hr className='my-2' />
                {
                    !loading && <ChartContainer config={chartConfig}>
                        <BarChart accessibilityLayer data={chartData}>
                            <CartesianGrid vertical={false} />
                            <XAxis dataKey="pmode" tickLine={false} tickMargin={10} axisLine={false} />
                            <YAxis dataKey="total" tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value.toFixed(2)} />
                            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                            <Bar dataKey="total" fill="#2563eb" radius={8} />
                        </BarChart>
                    </ChartContainer>
                }
            </div>
        </div>
    )
}
