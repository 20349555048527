import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Calendar as CalendarIcon } from "lucide-react";

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceOrder from 'services/serviceOrder';
import ServicePrinter from 'services/servicePrinter';

export default function PanelOrder(props) {

    const [token] = useRecoilState(tokenAtom);

    const [orders, setOrders] = useState([]);

    const [filter, setFilter] = useState({});
    const [ord, setOrd] = useState({});

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const orderService = new ServiceOrder();
    const printService = new ServicePrinter();

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setDate({
            from: Moment().startOf('year').toDate(),
            to: Moment().toDate(),
        });
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "open":
                setFilter({ 'status': 'open' });
                break;
            case "running":
                setFilter({ 'status': 'running' });
                break;
            case "paid":
                setFilter({ 'status': 'paid' });
                break;
            case "cancel":
                setFilter({ 'status': 'cancel' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const openPanel = (order) => {
        setOrd(order);
        setIsOpen(true);
    }

    const getOrderType = (ord) => {
        if (ord.ordRef === "takeaway") return "Take-Away";
        if (ord.ordRef === "delivery") return "Delivery";
        if (ord.isRoom) return "Room Service";
        if (!ord.isRoom) return "Table Service";
        return "NA"
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'running':
                return " bg-yellow-100 text-yellow-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getTotal = (ordItems) => {
        let total = 0;
        ordItems.forEach((a) => {
            total = total + (a.price * a.qty);
        });
        return total;
    }

    const getTax = (ordItems) => {
        let tax = 0;
        ordItems.forEach((a) => {
            tax = tax + ((a.price * a.qty) * 0.025);
        });
        return tax;
    }

    const printOption = () => {
        printService.getAllPrinterByType({ 'type': 'K' }, token).then((res) => {
            if (res.status) {
                res.data.map((item) => {
                    let payload = {
                        "name": "Hotel Kundan",
                        "unit": "Table 1",
                        "ordID": ord.ordID,
                        "ordItems": ord.ordItems,
                        "ordTime": ord.ordTime,
                        "provider": ord.provider
                    };
                    window.electronAPI.printKOT(payload, item.name, `${item.size}mm`).then((resPrint) => {
                        if (!resPrint.success) {
                            toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                });
            }
            toast.success("Order has sent to print!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        // orders.forEach((item) => {
        //     items.push({
        //         uid: item.uid,
        //         fname: item.fname,
        //         mname: item.mname,
        //         lname: item.lname,
        //         email: item.email,
        //         phone: item.phone,
        //         role: item.role,
        //         status: item.status,
        //         createdAt: item.createdAt,
        //     });
        // });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "staff");
        XLSX.writeFile(workbook, "staff.xlsx");
    }

    function getRoundOff(total, tax) {
        const finalTotal = total + (tax * 2);
        const roundedTotal = Math.round(finalTotal);
        const roundOffValue = (roundedTotal - finalTotal).toFixed(2);
        if (roundOffValue === "0.00") {
            return roundOffValue;
        }
        return roundOffValue > 0 ? `+${roundOffValue}` : roundOffValue;
    }

    const exportToCsv = () => {
        // let headers = ['uid,fname,mname,lname,email,phone,role,status'];
        // let usersCsv = orders.reduce((acc, user) => {
        //     const { uid, fname, mname, lname, email, phone, role, status } = user
        //     acc.push([uid, fname, mname, lname, email, phone, role, status].join(','))
        //     return acc
        // }, []);
        // downloadFile({
        //     data: [...headers, ...usersCsv].join('\n'),
        //     fileName: 'staff.csv',
        //     fileType: 'text/csv',
        // });
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getAllOrdersT(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setOrders(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await orderService.getAllOrdersT(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setOrders(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    return (
        <>
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>KOT #{ord.ordID}</h1>
                                    <span onClick={() => {
                                        setIsOpen(false);
                                        setOrd({});
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Order Type:
                                        <span className={`ml-4 px-3 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                            {getOrderType(ord)}
                                        </span>
                                    </p>
                                    <p className='ml-8 font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(ord.status)}`}>
                                            {ord.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-file-invoice mr-2 text-xl"></i>Items Summery
                                    </span>
                                </div>
                                <div className='mt-4 flex items-center'>
                                    <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-bold text-gray-950'>Item Unit</p>
                                </div>
                                <hr className='my-2' />
                                {
                                    ord.ordItems.map((item) => {
                                        return <div className='flex items-center'>
                                            <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                            <div className='flex-grow'></div>
                                            <p className='text-sm font-medium'>{item.qty} x ₹ {item.price} </p>
                                        </div>
                                    })
                                }
                                <hr className='my-2' />
                                <div class="w-full mt-2 space-y-1">
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">Subtotal</span>
                                        <span class="font-medium text-sm">₹ {getTotal(ord.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">CGST(2.5%)</span>
                                        <span class="font-medium text-sm">₹ {getTax(ord.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">SGST(2.5%)</span>
                                        <span class="font-medium text-sm">₹ {getTax(ord.ordItems).toFixed(2)}</span>
                                    </div>
                                    <div class="flex justify-between">
                                        <span class="font-medium text-sm">Round off</span>
                                        <span class="font-medium text-sm">₹ {getRoundOff(getTotal(ord.ordItems), getTax(ord.ordItems))}</span>
                                    </div>
                                    <div class="border-t-2 mt-2 py-2 flex items-center justify-between">
                                        <span class="font-medium text-lg">Total</span>
                                        <span class="font-medium text-lg">₹ {Math.round((getTotal(ord.ordItems) + (getTax(ord.ordItems) * 2))).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className='flex mt-4'>
                                    <div class="py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => { printOption(); }}>
                                        PRINT ORDER
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            <ScrollArea className="w-full h-full px-[4px]">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Order Tally
                            </h2>
                            <TopBar />
                        </div>
                        <div class="w-full mt-4 flex justify-between items-center">
                            <div className='h-10 bg-gray-100 rounded'>
                                <ul className="intro-y flex text-sm font-sans">
                                    <li className={`px-3 h-8 m-1 rounded flex items-center justify-center ${tab === 'all' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                    <li className={`px-3 h-8 m-1 rounded flex items-center justify-center ${tab === 'open' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('open') }}>Open</button></li>
                                    <li className={`px-3 h-8 m-1 rounded flex items-center justify-center ${tab === 'running' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('running') }}>Running</button></li>
                                    <li className={`px-3 h-8 m-1 rounded flex items-center justify-center ${tab === 'paid' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('paid') }}>Paid</button></li>
                                    <li className={`px-3 h-8 m-1 rounded flex items-center justify-center ${tab === 'cancel' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('cancel') }}>Cancelled</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                        <div className='w-full flex items-center space-x-2 px-2'>
                                            <form className="py-2 flex-grow flex items-center">
                                                <div className='flex'>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button id="date" variant={"outline"}
                                                                className={cn(
                                                                    "w-[300px] justify-start text-left font-normal",
                                                                    !date && "text-muted-foreground"
                                                                )}
                                                            >
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {date?.from ? (
                                                                    date.to ? (
                                                                        <>
                                                                            {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                            {Moment(date.to).format('MMM DD, yyyy')}
                                                                        </>
                                                                    ) : (
                                                                        Moment(date.from).format('MMM DD, yyyy')
                                                                    )
                                                                ) : (
                                                                    <span>Pick a date</span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                initialFocus
                                                                mode="range"
                                                                defaultMonth={date?.from}
                                                                selected={date}
                                                                onSelect={onDateChange}
                                                                numberOfMonths={2}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className='w-full flex items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and email." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='flex items-center'>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToExcel();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToCsv();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        ORDER ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        ORDER Type
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Order Items
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Order Price
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    orders.length !== 0 && orders.map((order) => {
                                        return <tr className="hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                <div className="text-sm font-bold text-gray-900 cursor-pointer" onClick={() => { openPanel(order) }}>
                                                    <div className='flex items-center text-prime'>
                                                        {order.ordID}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-green-100 text-green-800`}>
                                                    {order.provider}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {order.ordItems.length} x Items
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-prime">
                                                    ₹ {order.ordTotal.toFixed(2)}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${order.status === 'paid' ? 'bg-green-100 text-green-800' : order.status === 'running' ? 'bg-yellow-100 text-yellow-800' : 'bg-red-100 text-red-800'}`}>
                                                    {order.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600">
                                                    {moment(order.createdAt).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
