import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { motion } from "framer-motion";
import { toast } from 'react-toastify';

import { useRecoilState } from 'recoil';
import { tokenAtom, companyAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceCompany from '../../services/serviceCompany';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelSettingCompany(props) {

    const [token] = useRecoilState(tokenAtom);
    const [, setCompDB] = useRecoilState(companyAtom);

    const [comp, setComp] = useState({});

    const [submit, setSubmit] = useState(false);

    const companyService = new ServiceCompany();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        tname: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().required('This information is required'),
        gstn: Yup.string().matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, { message: 'Please enter valid GSTN number' }).required('This information is required'),
        address: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
            tname: '',
            email: '',
            phone: '',
            gstn: '',
            address: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "cid": comp._id,
                "data": {
                    name: values.name,
                    tradNme: values.tname,
                    email: values.email,
                    phone: values.phone,
                    gstn: values.gstn,
                    Addr: values.address,
                }
            }
            companyService.updateCompany(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    setCompDB(res.data);
                    toast.success("Company details updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const loadData = () => {
        (async function () {
            let body = {};
            var res = await companyService.getCompany(body, token);
            if (res['status']) {
                setComp(res.data);
                setCompDB(res.data);
                setValues({ ...values, name: res.data.name, tname: res.data.tradNme, email: res.data.email, phone: res.data.phone, gstn: res.data.gstn, address: res.data.Addr });
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Company Settings
                        </h2>
                        <TopBar />
                    </div>
                    <hr className='mt-2' />
                    <div className='w-full mt-4 ml-4'>
                        <h1 className='text-2xl font-medium'>Company settings page</h1>
                        <p className='text-sm'>To setup company settings, please contacy admin before making any changes</p>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="col-span-12 mt-4 ml-4">
                    <div className="shadow">
                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Restaurant name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="name" value={values.name} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. BookTable" />
                                    {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Restaurant Trade name<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="tname" value={values.tname} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. BookTable" />
                                    {(errors.tname && touched.tname) && <p className='text-xs text-red-400 mt-1'>{errors.tname}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="email" value={values.email} onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email address" />
                                    {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Phone number<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="phone" value={values.phone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" />
                                    {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">GSTN<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="gstn" value={values.gstn} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 27AAAAA1234AAZM" />
                                    {(errors.gstn && touched.gstn) && <p className='text-xs text-red-400 mt-1'>{errors.gstn}</p>}
                                </div>

                                <div className="col-span-6">
                                    <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                    <textarea id="address" value={values.address} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                    {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "SAVE DATA"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
