import * as React from "react";

import { useRecoilState } from 'recoil';
import { companyAtom } from 'core/config/atoms';

import moment from "moment";

export const PrintBill = React.forwardRef((props, ref) => {

    const [comp] = useRecoilState(companyAtom);

    function getRoundOff(total, tax) {
        const finalTotal = total + (tax * 2);
        const roundedTotal = Math.round(finalTotal);
        const roundOffValue = (roundedTotal - finalTotal).toFixed(2);
        if (roundOffValue === "0.00") {
            return roundOffValue;
        }
        return roundOffValue > 0 ? `+ ${roundOffValue}` : roundOffValue;
    }

    return (
        props.isVis ? <div className={`mx-auto my-5 w-[220px] text-sm p-2 ${props.isVis ? '' : 'hidden'}`} ref={ref}>
            <div className="space-y-1 border border-gray-800 bg-white">
                <h1 className="text-lg font-bold text-center">Tax Invoice</h1>
                <div className="text-center mb-3 flex flex-col items-center my-2 space-y-1">
                    <h2 className="text-center font-medium">{comp.tradNme}</h2>
                    <p className="text-center text-sm max-w-[240px]">{comp.Addr}</p>
                    <p className="text-center text-sm">Ph: {comp.phone}</p>
                </div>
                <div className="bg-black h-[1px]"></div>
                <div className="bg-black h-[1px]"></div>
                <div className="mt-2 text-xs px-2 space-y-1">
                    <div className="flex items-center justify-between">
                        <p><strong>Buyer:</strong> Cash</p>
                        <p><strong>Invoice No:</strong> {props.bill.invnNum}</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p><strong>Date:</strong> {moment(props.bill.ordTime).format("DD, MMM YY HH:MM A")}</p>
                        <p><strong>Table:</strong> {props.bill.unit}</p>
                    </div>
                </div>
                <div className="bg-black h-[1px]"></div>
                <table className="w-full border-collapse text-sm mt-2">
                    <thead>
                        <tr>
                            <th className="border border-black py-[2px] text-center">Sr No</th>
                            <th className="border border-black py-[2px] text-center">Particulars</th>
                            <th className="border border-black py-[2px] text-center">Qty</th>
                            <th className="border border-black py-[2px] text-center">Price</th>
                            <th className="border border-black py-[2px] text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.bill.items !== undefined && props.bill.items.map((item, index) => {
                                return <tr>
                                    <td className="border border-black py-[2px] text-center">{index + 1}</td>
                                    <td className="border border-black py-[2px] text-center">{item.name}</td>
                                    <td className="border border-black py-[2px] text-center">{item.qty}</td>
                                    <td className="border border-black py-[2px] text-center">{item.price}</td>
                                    <td className="border border-black py-[2px] text-center">{(item.qty * item.price).toFixed(2)}</td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1">Sub Total</td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {props.bill.total.toFixed(2)} /-</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1">CGST (2.5%)</td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {props.bill.tax.toFixed(2)} /-</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1">SGST (2.5%)</td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {props.bill.tax.toFixed(2)} /-</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1">Round off</td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {getRoundOff(props.bill.total, props.bill.tax)} /-</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1">Service Charges</td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {props.bill.serCharge.toFixed(2)} /-</strong></td>
                        </tr>
                        <tr>
                            <td colspan="3" className="border border-black py-[1px] text-right text-sm pr-1"><strong>Grand Total</strong></td>
                            <td colspan="2" className="border border-black py-[1px] text-right text-sm pr-1"><strong>₹ {(Math.round(props.bill.total + (props.bill.tax * 2)) + props.bill.serCharge).toFixed(2)} /-</strong></td>
                        </tr>
                    </tfoot>
                </table>
                <div className="text-center mt-3 font-bold text-sm">
                    <p>Thank you for visiting us!</p>
                </div>
            </div>
        </div> : <></>
    )
});
