import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, pathAtom, userAtom, userRoleAtom, companyAtom, subAtom } from '../../config/atoms';

import { ReceiptText, ChefHat, Inbox, SquareUser, Package, FileText, Settings, LogOut } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userRole, setUserRole] = useRecoilState(userRoleAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setUserData] = useRecoilState(userAtom);
    const [, setComp] = useRecoilState(companyAtom);
    const [sub, setSub] = useRecoilState(subAtom);

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        setUserRole("");
        setComp({});
        setSub({});
        navigate('/');
        setPath({ main: '', sub: '' });
    }

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getOrders = () => {
        if (page === 'order' || page === 'invoices' || page === 'booking') {
            return true;
        }
        return false;
    }

    const getInventory = () => {
        if (page === 'inventory' || page === 'inventoryList' || page === 'inventoryAdd' || page === 'raww' || page === 'rawwAdd' || page === 'bom' || page === 'bomAdd') {
            return true;
        }
        return false;
    }

    const getStaff = () => {
        if (page === 'staff' || page === 'staffAdd') {
            return true;
        }
        return false;
    }

    const getSettings = () => {
        if (page === 'setting' || page === 'subscription' || page === 'print' || page === 'company' || page === 'table' || page === 'room' || page === 'menu') {
            return true;
        }
        return false;
    }

    const getReports = () => {
        if (page === 'reports' || page === 'reportItem' || page === 'reportOrder' || page === 'reportInvoice' || page === 'reportBooking') {
            return true;
        }
        return false;
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden xs:block">
                <span className="cursor-pointer bg-gray-900 my-1" onClick={() => { changeMainMenu('pos') }}>
                    <div className="w-16 h-16 bg-gray-900">
                        <img src={icLogo} className="w-12 m-auto py-2" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%]'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    {
                        userRole !== 'KITCHEN' && <Tippy content="POS Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "pos" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('pos') }}>
                                <ReceiptText size={20} />
                            </li>
                        </Tippy>
                    }
                    {
                        sub.isKDS && <Tippy content="KDS Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "kds" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('kds') }}>
                                <ChefHat size={20} />
                            </li>
                        </Tippy>
                    }
                    {
                        userRole !== 'KITCHEN' && <Tippy content="Orders Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getOrders() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('order') }}>
                                <Inbox size={20} />
                            </li>
                        </Tippy>
                    }
                    {
                        sub.isInv && <Tippy content="Inventory Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getInventory() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('inventory') }}>
                                <Package size={20} />
                            </li>
                        </Tippy>
                    }
                    {
                        userRole === 'ADMIN' && <Tippy content="Staff Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getStaff() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('staff') }}>
                                <SquareUser size={20} />
                            </li>
                        </Tippy>
                    }
                    {
                        (userRole === 'ADMIN' || userRole === 'MANAGER') && < Tippy content="Reports Section" placement='right'>
                            <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getReports() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('reports') }}>
                                <FileText size={20} />
                            </li>
                        </Tippy>
                    }
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getSettings() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white text-white hover:text-ascent`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
                <p className='text-sm text-center text-white'>v0.0.1a</p>
            </div >
            <div className="fixed bottom-0 bg-white nShadow h-12 w-full rounded-t-md px-2 xs:hidden">
            </div>
        </>
    )
}
