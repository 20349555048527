import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import ServiceOverview from 'services/serviceOverview';

export default function ComponentListOrder(props) {

    const [token] = useRecoilState(tokenAtom);

    const [order, setOrder] = useState([]);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const loadData = () => {
        (async function () {
            if (props.range !== undefined && props.range.from !== undefined && props.range.to !== undefined) {
                let body = {
                    "startDate": props.range.from.toISOString(),
                    "endDate": props.range.to.toISOString(),
                }
                var res = await overviewService.getTopOrder(body, token);
                if (res['status']) {
                    setOrder(res.data);
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [props.range]);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Recent Orders | Top 10</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (order.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No data found.</p>
                    </div>
                }
                <div className='space-y-2'>
                    {
                        order.map((ord) => {
                            return <div className='w-full flex'>
                                <div className='flex flex-col items-start justify-start'>
                                    <h1 className='text-sm font-medium text-black'>{ord.ordID}</h1>
                                    <p className='text-xs font-medium text-gray-600'>Items ({ord.ordItems.length})</p>
                                </div>
                                <div className='flex-grow'></div>
                                <div className='text-sm text-green-600 font-medium'>
                                    <p>₹ {ord.ordTotal.toFixed(2)}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
