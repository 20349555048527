import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "lucide-react";

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import Moment from 'moment';

import TopBar from '../../core/widgets/ui/TopBar';

import animPage from '../../assets/anim/anim-pageload.json';

import ComponentCardOrderCount from './components/card/ComponentCardOrderCount';
import ComponentCardInvoiceCount from './components/card/ComponentCardInvoiceCount';
import ComponentCardCashCount from './components/card/ComponentCardCashCount';
import ComponentCardNVCount from './components/card/ComponentCardNVCount';

import ComponentListOrder from './components/list/ComponentListOrder';
import ComponentListItem from './components/list/ComponentListItem';
import ComponentListChart from './components/list/ComponentListChart';

import ComponentTableOrder from './components/table/ComponentTableOrder';

export default function PanelReports(props) {

    const [date, setDate] = useState({
        from: Moment().toDate(),
        to: Moment().toDate(),
    });

    const [loading, setLoading] = useState(true);

    const onDateChange = (event) => {
        setDate(event);
    }

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-full py-2 px-2">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Reports Overview
                        </h2>
                        <TopBar />
                    </div>
                    <div class="w-full h-full">
                        {
                            !loading && <>
                                <div className='flex items-center justify-between my-2'>
                                    <h1 className='text-xl font-medium truncate ml-2'>Overview Sales Report</h1>
                                    <div>
                                        <Popover>
                                            <PopoverTrigger asChild>
                                                <Button id="date" variant={"outline"}
                                                    className={cn("w-[300px] justify-start text-left font-normal", !date && "text-muted-foreground")}>
                                                    <CalendarIcon className="mr-2 h-4 w-4" />
                                                    {date?.from ? (
                                                        date.to ? (
                                                            <>
                                                                {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                {Moment(date.to).format('MMM DD, yyyy')}
                                                            </>
                                                        ) : (
                                                            Moment(date.from).format('MMM DD, yyyy')
                                                        )
                                                    ) : (
                                                        <span>Pick a date</span>
                                                    )}
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="w-auto p-0" align="start">
                                                <Calendar
                                                    initialFocus
                                                    mode="range"
                                                    defaultMonth={date?.from}
                                                    selected={date}
                                                    onSelect={onDateChange}
                                                    numberOfMonths={2}
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2">
                                    <ComponentCardOrderCount range={date} />
                                    <ComponentCardInvoiceCount range={date} />
                                    <ComponentCardCashCount range={date} />
                                    <ComponentCardNVCount range={date} />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                    <ComponentListOrder range={date} />
                                    <ComponentListItem range={date} />
                                    <ComponentListChart range={date} />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                    <ComponentTableOrder range={date} />
                                </div>
                                <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
