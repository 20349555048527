import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import Skeleton from 'react-loading-skeleton';

import { motion } from "framer-motion";

import ServiceOverview from 'services/serviceOverview';

export default function ComponentCardCashCount(props) {

    const [token] = useRecoilState(tokenAtom);

    const [counts, setCounts] = useState(0);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const loadData = () => {
        (async function () {
            if (props.range !== undefined && props.range.from !== undefined && props.range.to !== undefined) {
                let body = {
                    "startDate": props.range.from.toISOString(),
                    "endDate": props.range.to.toISOString(),
                }
                const res = await overviewService.getCashCount(body, token);
                setCounts(res.data);
                setLoading(false);
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, [props.range]);

    return (
        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
            {
                !loading && <div className="shadow bg-green-200 rounded-lg">
                    <div className="box p-5">
                        <div className="flex items-center">
                            <i className="las la-dollar-sign text-4xl 2xl:text-6xl text-green-600"></i>
                            <div className="ml-auto text-right">
                                <div className="text-2xl font-bold leading-8">₹ {counts.toFixed(2)}</div>
                                <div className="text-xs text-gray-600 mt-1">Total Sales</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Skeleton className='h-[100px]' />}
        </motion.div>
    )
}
